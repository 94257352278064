.news{
  &-item{
    // background-color: #042a58;
    
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: cover;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 230px;
    overflow: hidden;
    transition: all .3s ease;
    cursor: pointer;
    text-decoration: none;
    width: 50%;
    border-bottom: 1px solid #153c6a;
    border-right: 1px solid #153c6a;

    @include tablets{
      width: 100%;
      border-right: none;
    }

    &_full{
      width: 100%;
    }

    &:hover{
      background-image: url(../img/news-bg.png);

      .news-item__date{
        &:after{
          background-color: #002552;
        }

        svg{
          fill: #002552;
        }

        span{
          color: #002552;
          background-color: #183d66;
        }
      }

      .news-item__btn{
        svg{
          display: none;
        }
      }
    }

    &__new{
      display: none;
      padding: 5px 10px;
      background-color: $blue-l;
      border-radius: 4px;
      text-align: center;
      font-family: $d;
      font-weight: 900;
      font-size: 12px;
      text-transform: uppercase;
      color: #fff;
    }

    &__top{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__text{
      flex: 1 0 auto;
      color: #fff;
      font-size: 13px;
      font-weight: 200;
      margin-bottom: 25px;
      

      p{
        line-height: 1.5;
      }

      &_right{
        padding: 20px;
        border-left: 1px solid #153c6a;

        @include tablets{
          padding: 0;
          border-left: none;
        }
      }
    }

    &__title{
      margin-bottom: 15px;
      line-height: 1.5;
      position: relative;
      font-family: $d;
      font-weight: 700;
      font-size: 18px;
      color: #fff;
      padding-top: 25px;

      &:after{
        position: absolute;
        top: 0;
        left: 0;
        background-color: $orange;
        width: 50px;
        height: 3px;
        border-radius: 6px;
        content: '';
      }
    }

    &__date{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      span{
        color: #2c4d76;
        background-color: #022146;
        padding: 5px;
        border-radius: 3px;
        font-size: 10px;
        font-weight: 400;
      }

      &:after{
        right: 100%;
        @include posY;
        width: 40px;
        height: 1px;
        background-color: #153c6a;
        content: '';
      }

      svg{
        width: 14px;
        height: 14px;
        margin-right: 10px;
        fill: #153c6a;
      }
    }

    &__btn{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      
      .main-btn{
        font-size: 12px;
        padding: 0 15px;
        height: 30px;
        pointer-events: none;
        border-radius: 6px;
      }

      svg{
        transform: scaleX(-1);
        fill: #2c4d76;
        width: 17px;
        height: 17px;
        margin-left: 15px;
        transition: all .3s ease;
      }
    }

    &__box{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @include tablets{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &__col{
      width: 100%;

      &:first-child{
        max-width: 300px;
      }
    }

    &_new{
      .news-item__new{
        display: inline-block;
      }
    }

    &_activity{
      .news-item__new{
        display: inline-block;
      }
    }
  }

  &-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid #153c6a;
    border-radius: 8px;
    margin-bottom: 40px;
    overflow: hidden;
  }

  &-full{

    &__title{
      font-family: $d;
      font-weight: 700;
      font-size: 35px;
      color: #fff;
      margin-bottom: 40px;
    }

    .news-item__date{
      margin-bottom: 40px;
      padding-top: 0;
      border: none;
    }

    img{
      max-width: 100%;
      margin: 0 auto;
      border-radius: 8px;
      margin-bottom: 30px;
      display: inline-block;
    }

    p{
      color: #fff;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.pagination{
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  border: 1px solid #153c6a;
  border-radius: 8px;

  a{
    border-right: 1px solid #153c6a;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    min-height: 35px;
    text-decoration: none;
    color: #fff;
    font-family: $d;
    font-size: 14px;
    font-weight: 900;
    transition: all .3s ease;
    position: relative;

    &:after{
      width: 0;
      opacity: 0;
      height: 1px;
      bottom: 4px;
      background-color: $orange;
      content: '';
      @include posX;
      transition: all .3s ease;
    }
    
    &:hover, &.active{
      color: $orange;

      &:after{
        width: 70%;
        opacity: 1;
      }
    }

    &:last-child{
      border-right: none;
    }
  }
}