.roadmap{
  background-image: url(../img/roadmap-bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 150px 0 20px 0;

  .main-text{
    p{
      font-size: 14px;
    }
  }

  &-slider{
    width: 100%;
    position: relative;

    &:after{
      pointer-events: none;
      @include posX;
      bottom: -20px;
      z-index: 1;
      content: '';
      width: calc(100% + 40px);
      height: 100%;
      border-radius: 10px;
      background: linear-gradient(0deg, rgba(#69788c, .1) 0%, rgba(#69788c, 0) 100%);
    }

    .swiper{
      &-container{
        width: 100%;
        z-index: 2;
      }

      &-pagination{
        position: static;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        &-bullet{
          width: 45px;
          height: 45px;
          border-radius: 8px;
          background-color: #2a5282;
          color: #0c3566;
          transition: all .3s ease;
          opacity: 1;
          margin-right: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: $dc;
          font-weight: 900;
          font-size: 22px;
          cursor: pointer;
          outline: none;

          @include phones-hor{
            width: 35px;
            height: 35px;
            font-size: 18px;
          }

          &-active, &:hover{
            background-color: $orange;
          }
        }
      }

      &-button{
        &-prev, &-next{
          background: none;
          position: relative;
          margin: 0;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          transform: translate(0, 0);
          width: auto;
          height: auto;
          display: flex;
          outline: none;

          &:hover svg{
            opacity: 1;
          }

          svg{
            width: 15px;
            height: 15px;
            fill: #fff;
            opacity: 0.3;
            transition: all .3s ease;
          }
        }

        &-next{
          transform: translate(0, 0) scaleX(-1);
        }
      }
    }

    &__nav{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      position: relative;
      z-index: 2;
    }
  }

  &-item{
    padding-bottom: 30px;
    border-bottom: 1px solid #2c5484;

    &__title{
      color: #2c5484;
      font-weight: 800;
      font-size: 17px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &__percent{
      font-family: $d;
      font-weight: 900;
      font-size: 35px;
      color: #fff;
      margin-bottom: 25px;
    }

    .main-text{
      margin-bottom: 0;
    }
  }

  &-nav{
    position: relative;
    width: 100%;
    height: 480px;
    background-image: url(../img/roadmap-map.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;

    @include phones-hor{
      display: none;
    }


    &__item{
      position: absolute;
      width: 62px;
      height: 30px;
      transition: all .3s ease;
      cursor: pointer;

      &:nth-child(1){
        right: 28%;
        bottom: 43%;
        
        &.active{
          height: 60px;
        }
      }
      &:nth-child(2){
        left: 37%;
        bottom: 43%;

        &.active{
          height: 130px;
        }
      }
      &:nth-child(3){
        left: 48%;
        bottom: 77%;

        &.active{
          height: 120px;
        }
      }
      &:nth-child(4){
        left: 17%;
        bottom: 78%;

        &.active{
          height: 160px;
        }
      }
      &:nth-child(5){
        left: 13%;
        bottom: 55%;

        &.active{
          height: 90px;
        }
      }

      &.active, &:hover{

        span{
          &:after, &:before{
            background-color: $orange;
          }
        }
        .roadmap-nav__item-top{
          background-color: $orange;
        }
        .roadmap-nav__item-left{
          background-color: rgba($orange, .71);
        }
        .roadmap-nav__item-right{
          background-color: rgba($orange, .84);
        }
      }

      span{
        position: absolute;
        top: -75px;
        right: 15px;
        font-family: $dc;
        font-weight: 900;
        font-size: 16px;
        color: #fff;

        &:after{
          @include posX;
          top: calc(100% + 5px);
          border-radius: 50%;
          background-color: $blue-l;
          width: 7px;
          height: 7px;
          content: '';
          transition: all .3s ease;
        }

        &:before{
          @include posX;
          top: calc(100% + 5px);
          height: 35px;
          width: 1px;
          background-color: $blue-l;
          content: '';
          transition: all .3s ease;
        }
      }

      &-top{
        transform: rotate3d(1, 0, 0, 135deg) rotate3d(0, 0, 1, 40deg) skewX(-11deg);
        width: 46px;
        height: 38px;
        position: absolute;
        bottom: 100%;
        right: 9px;
        background-color: $blue-l;
        margin-bottom: -12px;
        transition: all .3s ease;
      }
      &-left{
        background-color: rgba($blue-l, .71);
        height: 100%;
        width: 30px;
        transform: skewY(30deg);
        position: absolute;
        right: 34px;
        bottom: -2px;
        transition: all .3s ease;
      }
      &-right{
        background-color: rgba($blue-l, .84);
        height: 100%;
        width: 34px;
        transform: skewY(-30deg);
        position: absolute;
        right: 0;
        bottom: 0;
        transition: all .3s ease;
      }
    }
  }
}