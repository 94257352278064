.advantage{
  padding: 50px 0;

  &-list{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;

    @include phones{
      flex-wrap: wrap;
    }
  }

  &-item{
    width: 100%;
    padding: 60px 50px 0 50px;
    position: relative;
    margin-bottom: 30px;
    border-right: 1px solid #0c3566;

    @include desktop{
      padding: 60px 30px 0 30px;
    }

    &:last-child{
      border-right: none;
    }

    @include laptop{
      border: none;
      padding: 60px 5px 0 5px;
    }
    

    @include phones{
      width: 50%;
      max-width: 50%;
    }

    img{
      max-width: 100%;
      top: 0;
      left: 50px;
      position: absolute;
      max-height: 50px;

      @include desktop{
        left: 30px;
      }

      @include laptop{
        left: 5px;
      }
    }

    p{
      color: #33537b;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.4;
    }
  }

  &-title{
    text-transform: uppercase;
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 30px;
  }

  .main-btn{
    margin-bottom: 30px;
  }

  &-check{
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px;

    @include tablets{
      padding-left: 0;
    }

    &__icon{
      position: absolute;
      top: -5px;
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: 8px;
      border: 1px solid rgba(#fff, .05);

      svg{
        @include posXY;
        fill: $blue-l;
        width: 12px;
        height: 12px;
      }
    }

    

    &__item{
      padding-left: 50px;
      position: relative;
      margin-bottom: 20px;

      &:last-child{
        &:after{
          display: none;
        }
      }

      &:after{
        position: absolute;
        left: 14px;
        width: 1px;
        background-color: rgba(#fff, .05);
        height: calc(100% - 20px);
        top: 30px;
        content: '';
      }

      &:last-child{
        margin-bottom: 0;
      }

      p{
        color: #2c5382;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }
}