.wallets{
  padding: 60px 0;
  background-color: #01234d;

  @include laptop{
    padding: 40px 0;
  }

  @include tablets{
    padding: 30px 0;
  }

  &__list{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include tablets{
      margin-bottom: -10px;
    }
  }

  &__item{
    width: calc(100% / 8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    filter: brightness(1.1);

    @include tablets{
      width: calc(100% / 4);
      margin-bottom: 10px;
    }

    img{
      max-width: 100%;
    }
  }

  &-slider{
    width: 100%;

    &__item{
      width: 100%;
      filter: brightness(1.1);

      img{
        max-width: 100%;
      }
    }

    .swiper{
      &-container{
        width: 100%;
        margin-bottom: 25px;
      }

      &-wrapper{
        align-items: center;
      }

      &-button{
        &-prev, &-next{
          background: none;
          position: relative;
          margin: 0;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          transform: translate(0, 0);
          width: auto;
          height: auto;
          display: flex;
          outline: none;

          &:hover svg{
            opacity: 1;
          }

          svg{
            width: 15px;
            height: 15px;
            fill: #fff;
            opacity: 0.08;
            transition: all .3s ease;
          }
        }

        &-next{
          transform: translate(0, 0) scaleX(-1);
        }
      }
    }

    &__nav{
      max-width: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include tablets{
        margin-bottom: 30px;
        margin: 0 auto 30px auto;
      }
    }
  }
}