.invest{
  padding: 20px 0 0 0;

  &-title{
    position: relative;
    padding-left: 70px;
    margin-bottom: 30px;
    font-family: $d;
    font-weight: 700;
    font-size: 22px;
    color: #fff;

    

    &__icon{
      width: 30px;
      height: 30px;
      border: 1px solid rgba(#fff, .08);
      border-radius: 8px;
      @include posY;
      left: 20px;

      svg{
        @include posXY;
        fill: #fff;
        width: 14px;
        height: 14px;
      }
    }

    &_right{
      padding-left: 0;
      padding-right: 70px;
      text-align: right;
      margin-bottom: 20px;

      .invest-title__icon{
        right: 0;
        left: auto;
      }
    }
  }

  &-slider{
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .stat-slider__nav{
      position: static;
    }

    .swiper{
      &-container{
        padding: 20px 0;
        height: 320px;
        width: 100%;

        @include phones-hor{
          height: 420px;
        }
      }

      &-button{
        &-prev, &-next{
          background: none;
          position: relative;
          margin: 0;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          transform: translate(0, 0);
          width: auto;
          height: auto;
          display: flex;
          outline: none;

          &:hover svg{
            opacity: 1;
          }

          svg{
            width: 15px;
            height: 15px;
            fill: #fff;
            opacity: 0.18;
            transition: all .3s ease;
          }
        }

        &-next{
          transform: translate(0, 0) scaleX(-1);
        }
      }
  
      &-slide{
        opacity: 0;
        transform: translateY(-15px);
        z-index: 1;
        transform: translateY(0) scale(.8);
        transition: all .3s ease;
  
        &-active{
          opacity: 1;
          z-index: 3;
          transform: translateY(0) scale(1);
        }
  
        &-next{
          opacity: 0.68;
          z-index: 2;
          transform: translateY(0) scale(.9);
  
          & + .swiper-slide{
            opacity: .28;
          }
        }
      }
    }
  }

  .main-text{
    position: relative;
    padding-left: 70px;
    margin-bottom: 30px;

    &:after{
      height: 100%;
      bottom: 0;
      left: 34px;
      position: absolute;
      content: '';
      background-color: rgba(#fff, .1);
      width: 1px;
    }
  }
}

.plans{
  width: 100%;

  
  
  .tabs{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .tab{
    width: calc(100% / 3);
    color: #4d7cb7;
    position: relative;
    padding: 15px 70px 15px 20px;
    background-color: transparent;
    cursor: pointer;

    &.active, &:hover{
      background-color: #062b59;

      span{
        color: $orange;
      }

      p{
        color: #fff;
      }

      &:after{
        height: 3px;
        background-color: $orange;
      }
    }

    img{
      @include posY;
      max-width: 50px;
      right: 15px;

      @include phones-hor{
        max-width: 30px;
        top: 25%;
      }
    }

    span{
      font-family: $dc;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 10px;
      transition: all .3s ease;

      @include phones-hor{
        font-size: 10px;
      }
    }

    p{
      font-family: $d;
      font-weight: 700;
      font-size: 16px;
      transition: all .3s ease;

      @include phones-hor{
        font-size: 12px;
      }
    }

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(#fff, .05);
      transition: all .3s ease;
    }
  }
}

.plan{
  position: relative;
  background-image: url(../img/plan-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 120px;
  border-radius: 3px;

  @include phones{
    padding-left: 20px;
  }

  &-percent{
    @include posY;
    width: 120px;
    text-align: center;
    font-family: $dc;
    font-weight: 900;
    font-size: 36px;
    color: $orange;
    left: 0;

    @include phones{
      position: static;
      padding: 10px 20px 10px 0;
      text-align: right;
      width: 100%;
      transform: translate(0, 0);
      font-size: 26px;
      padding-right: 20px;
    }
  }

  &-icon{
    left: 20px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 5px solid #0c3566;
    width: 40px;
    height: 40px;
    background-color: $orange;

    svg{
      @include posXY;
      width: 12px;
      height: 12px;
      fill: #0c3566;
    }
  }

  &-top{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 30px 150px 20px 0;
    border-bottom: 1px solid rgba(#fff, .05);

    @include phones-hor{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px 150px 10px 0;
    }
  }

  &-bot{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;

    @include phones-hor{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .plan-info{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      span{
        margin-top: 0;
        margin-right: 15px;
      }
    }
  }

  &-btn{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba($orange, .05);
    text-decoration: none;
    font-family: $dc;
    font-weight: 900;
    font-size: 16px;
    color: $orange;
    text-transform: uppercase;
    transition: all .3s ease;
    border-top-right-radius: 3px;

    &:hover{
      color: $blue-l;
      background-color: $orange;

      &:after{
        border-right-color:  $blue-l; 
        border-bottom-color: $blue-l;
      }
    }

    &:after{
      border: 5px solid transparent; 
      border-right-color:  $orange; 
      border-bottom-color: $orange;
      position: absolute;
      right: 0;
      bottom: 0;
      content: '';
      transition: all .3s ease;
    }
  }

  &-day{
    position: relative;
    padding-left: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;

    @include phones-hor{
      padding-left: 0;
    }

    &__num{
      font-weight: 700;
      color: #fff;
      font-size: 25px;
      margin-right: 10px;
    }

    &__text{
      color: #fff;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 400;
    }

    svg{
      @include posY;
      left: 0;
      transform: translate(-50%, -50%);
      fill: #fff;
      opacity: 0.05;
      width: 15px;
      height: 15px;

      @include phones-hor{
        display: none;
      }
    }
  }

  &-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include phones-hor{
      margin-bottom: 5px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    p{
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      margin-top: 5px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    span{
      font-size: 10px;
      text-transform: uppercase;
      color: $blue-l;
      margin-top: 5px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}