.footer{
  padding: 20px 0;
  @include bgCover('footer-bg.png')

  @include tablets{
    padding: 30px 0;
  }

  .logo{
    margin-bottom: 25px;
  }

  .main-nav{
    margin-bottom: 25px;
    flex-wrap: wrap;
    width: calc(100% - 20px);
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    border-right: 1px solid #0b3465;

    @include desktop{
      width: 100%;
    }

    @include tablets{
      border-right: none;
    }

    a{
      margin-bottom: 20px;
      width: 50%;
      font-size: 12px;

      border-right: none;
    }
  }

  &-text{
    margin-bottom: 30px;
    padding-top: 30px;
    max-width: 180px;
    position: relative;

    &:before{
      position: absolute;
      top: 0px;
      left: 0;
      height: 5px;
      width: 55px;
      background-color: $orange;
      border-radius: 20px;
      content: '';
    }

    &:after{
      position: absolute;
      left: 60px;
      top: 0px;
      background-color: $orange;
      border-radius: 50%;
      height: 5px;
      width: 5px;
      content: '';
    }

    p{
      color: #fff;
      font-family: $d;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .header-contacts{
    flex-wrap: wrap;

    &__item{
      width: 50%;
      margin-bottom: 40px;

      

      @include laptop{
        width: 100%;
      }

      &_address, &_soc{
        width: 100%;
      }
    }
  }

  &-soc{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    a{
      position: relative;
      border-radius: 9px;
      width: 40px;
      height: 40px;
      background-color: $blue;
      transition: all .3s ease;
      margin-right: 10px;
      margin-bottom: 10px;

      &:last-child{
        margin-right: 0;
      }

      &:hover{
        background-color: $orange;

        svg{
          fill: $blue;
        }
      }

      svg{
        @include posXY;
        width: 17px;
        height: 17px;
        fill: #fff;
        transition: all .3s ease;
      }
    }
  }

  .main-btn{
    @include tablets{
      margin-bottom: 30px;
    }
  }
}

.copyright{
  font-family: $d;
  font-weight: 400;
  font-size: 12px;
  color: #244c7d;

  @include tablets{
    margin-bottom: 20px;
    width: 100%;
  }
}