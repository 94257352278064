@import 'common/normalize';
@import 'common/fonts';
@import 'common/mixin';
@import 'common/animation';

$c: 'ProximaNova', sans-serif;
$d: 'DINPro', sans-serif;
$dc: 'DINProCond', sans-serif;
$p: 'ProximaNova', sans-serif;
$s: 'Sochi2014', sans-serif;
$orange: #fdd186;
$gray: #606060;
$blue: #2b4476;
$blue-l: #3579ca;

*{
  box-sizing: border-box;
}

html, body{
  font-family: $c;
  font-weight: 300;
  background-image: url(../img/body-bg.png);
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center center;
}

html{
  font-size: 14px;

  @include phones {
    font-size: 12px;
  }
}
p, h1, h2, h3, h4{
  margin: 0;
  padding: 0;
  line-height: 1;
}
ul{
  margin: 0;
  padding: 0;
  list-style-type: none;
}

sup, sub{
  font-size: 60%;
}

sup{
  top: -3px;
}

sub{
  bottom: 0;
  text-transform: uppercase;
}

.wrapper{
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.content{
  flex: 1 0 auto;
}

.footer{
  flex-shrink: 0;
}

.svg-gradients{
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

@supports(-webkit-background-clip: text){ 
  //purple

  .main-title-wrap{
    background: linear-gradient(0deg, rgba(67, 54, 182, 1) 0%, rgba(164, 78, 208, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.main-bg{
  background-color: #002654;
}

.terms{
  padding: 50px 0;

  h4{
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    color: $orange;
  }

  p{
    line-height: 1.4;
    margin-bottom: 20px;
    font-size: 14px;
    color: $gray;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .item{
    margin-bottom: 30px;

    &-text{
      margin-bottom: 30px;
    }
  }
}

.btc{
  background-image: url(../img/btc.svg);
}
.bch{
  background-image: url(../img/bch.svg);
}
.tron{
  background-image: url(../img/tron.svg);
}
.dash{
  background-image: url(../img/dash.svg);
}
.doge{
  background-image: url(../img/doge.svg);
}
.eth{
  background-image: url(../img/eth.svg);
}
.ltc{
  background-image: url(../img/ltc.svg);
}
.monero{
  background-image: url(../img/monero.svg);
}
.payeer{
  background-image: url(../img/payeer.svg);
}
.pm{
  background-image: url(../img/pm.svg);
}
.ripple{
  background-image: url(../img/ripple.svg);
}
.ethc{
  background-image: url(../img/ethc.svg);
}

.mfp-close{
  color: #fff !important;
  font-size: 16px !important;
}

.main-btn{
  height: 50px;
  padding: 0 30px;
  background-color: $orange;
  color: $blue;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  text-align: center;
  font-family: $dc;
  font-weight: 900;
  font-size: 18px;
  text-decoration: none;
  outline: none;
  border: none;
  transition: all .3s ease;
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.13);
  white-space: nowrap;
  cursor: pointer;

  &:hover{
    background-color: $blue;
    color: #fff;
  }

  &_blue{
    background-color: $blue;
    color: #fff;

    &:hover{
      background-color: $orange;
      color: $blue;
    }
  }

  &_cab{
    background-color: #244670;
    color: #002654;

    &:hover{
      background-color: $blue;
      color: #fff;
    }
  }

  &_transparent{
    background-color: transparent;
    color: #244670;
    border: 1px solid #244670;

    &:hover{
      background-color: $blue;
      color: #fff;
    }
  }

  &_icon{
    padding-left: 20px;
    padding-right: 65px;
    position: relative;

    img{
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.main-text{
  width: 100%;
  margin-bottom: 30px;

  b{
    font-weight: 700;
  }

  p{
    color: #fff;
    line-height: 1.4;
    margin-bottom: 30px;

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.text-center{
  text-align: center;
}

.main-title{
  font-family: $d;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;

  span{
    color: $orange;
  }

  &_lower{
    text-transform: none;
  }

  &_small{
    font-size: 24px;
  }
}

.main-subtitle{
  position: relative;
  padding-left: 80px;
  margin-bottom: 30px;
  color: $orange;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  display: block;
  min-height: 5px;

  &:before{
    @include posY;
    left: 0;
    height: 5px;
    width: 55px;
    background-color: $orange;
    border-radius: 20px;
    content: '';
  }

  &:after{
    @include posY;
    left: 60px;
    background-color: $orange;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    content: '';
  }

  svg{
    @include posY;
    left: 0;
    width: 20px;
    height: 20px;
    fill: $orange;
  }

  &_icon{
    padding-left: 35px;
    
    &:after, &:before{
      display: none;
    }
  }
}

.map{
  width: 100%;
  height: 220px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 30px;

  iframe{
    width: 100%;
    height: 100%;
  }
}

@import 'common/header';
@import 'common/first';
@import 'common/invest';
@import 'common/affil';
@import 'common/about';
@import 'common/cert';
@import 'common/stat';
@import 'common/roadmap';
@import 'common/wallets';
@import 'common/quote';
@import 'common/advantage';
@import 'common/news';
@import 'common/sign';
@import 'common/footer';
@import 'common/cabinet';

