.first{
  position: relative;
  min-height: 100vh;
  padding-bottom: 20px; 
  background-repeat: no-repeat;
  background-size: cover;

 &-sidebar{
   position: relative;

   &:after{
     @include posY;
     top: calc(50% - 15px);
     height: calc(100% + 30px);
     right: 15px;
     background-color: rgba(#fff, 0.05);
     width: 1px;
     content: '';
     z-index: -1;

     @include laptop{
       display: none;
     }
   }
 }

  &-scene{
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;

    &__bg{
      display: flex;
    }

    &__icons{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &__icon{
      position: absolute;
      // opacity: 0;
      bottom: 0;
      left: 0;

      img{
        max-width: 100%;
      }

      &:nth-child(1){
        animation: road1 2s infinite linear;
      }
      &:nth-child(2){
        animation: road2 2s infinite linear;
        animation-delay: .5s;
      }

      &:nth-child(3){
        animation: road3 2s infinite linear;
        animation-delay: 1s;
      }
      &:nth-child(4){
        animation: road4 2s infinite linear;
        animation-delay: 1s;
      }
      &:nth-child(5){
        animation: road5 2s infinite linear;
        animation-delay: .5s;
      }
      &:nth-child(6){
        animation: road6 2s infinite linear;
      }
    }
  } 

  .container{
    z-index: 2;
    position: relative;
  }

  &-subtitle{
    padding-top: 60px;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: $d;

    &:before{
      position: absolute;
      top: 20px;
      left: 0;
      height: 5px;
      width: 50px;
      background-color: $orange;
      border-radius: 20px;
      content: '';
    }

    &:after{
      position: absolute;
      left: 60px;
      top: 20px;
      background-color: $orange;
      border-radius: 50%;
      height: 5px;
      width: 5px;
      content: '';
    }
  }

  &-title{
    font-family: $d;
    font-weight: 900;
    font-size: 45px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 40px;

    @include phones-hor{
      font-size: 40px;
    }

    span{
      color: $blue-l;
    }
  }

  .main-text{
    max-width: 430px;
  }

  &-content{

    .main-text{
      max-width: 100%;
    }

    @include laptop{
      padding-top: 50px;
    }
  }

  &_contacts{
    background-image: url(../img/contacts-bg.png);
  }

  &_bg{
    background-image: url(../img/first-bg.png);
  }
  &_sign{
    background-image: url(../img/sign-bg.png);
  }
  &_about{
    background-image: url(../img/about-bg.png);
  }
}

.logo{
  margin-bottom: 30px;
  max-width: 180px;
  display: inline-block;

  @include laptop{
    margin-bottom: 30px;
  }

  img{
    max-width: 100%;
  }
}

.main-nav{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 40px;

  @include laptop{
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @include tablets{
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  a{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    color: #fff;
    font-family: $d;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 30px;
    transition: all .3s ease;
    line-height: 1.5;
    width: 100%;
    position: relative;
    padding-left: 15px;
    border-right: 1px solid transparent;
    

    @include laptop{
      margin-bottom: 0;
      width: auto;
      border-right: none !important;
    }

    @include tablets{
      width: 25%;
    }

    &:after{
      position: absolute;
      top: 7px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $orange;
      opacity: 0.05;
      content: '';
      transition: all .3s ease;
    }

    &:last-child{
      margin-bottom: 0;
    }
    
    &:hover{
      border-right: 1px solid $orange;
      color: $orange;

      &:after{
        opacity: 1;
      }
    }

    &.active{
      font-size: 18px;
      font-weight: 900;
      border-right: 1px solid $orange;
      color: $orange;

      &:after{
        opacity: 1;
        top: 12px;
      }
    }

    span{
      font-family: $c;
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
      color: rgba(#fff, .3);
    }
  }
}

.soc{
  position: relative;
  max-width: 260px;
  width: 100%;
  padding: 15px 50px 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid rgba(#fff, .05);

  @include laptop{
    border-top: none;
    max-width: 100%;
  }

  span{
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    color: $orange;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
    white-space: nowrap;
    padding: 3px 10px;
    background-color: #002654;

    @include laptop{
      background-color: transparent;
    }
  }
  
  & > a{
    display: inline-block;
    width: 33%;
    margin: 10px 0;

    &:hover{
      & > svg{
        fill: $orange;
      }
    }

    & > svg{
      width: 25px;
      height: 25px;
      fill: #fff;
      transition: all .3s ease;
    }
  }
}
