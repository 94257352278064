.sign{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-right: 190px;

  @include phones-hor{
    padding-right: 40px;
  }

  &-input{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    border: 1px solid #33537b;
    border-radius: 8px;
    position: relative;
    margin-bottom: 15px;

    @include phones-hor{
      height: 50px;
    }

    &__check{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @include posY;
      left: calc(100% + 70px);
      border: 1px solid #33537b;

      @include phones-hor{
        left: calc(100% + 10px);
        width: 30px;
        height: 30px;
      }

      svg{
        z-index: 2;
        @include posXY;
        fill: #fff;
        width: 11px;
        height: 11px;
        transition: all .3s ease;
        opacity: 0;
      }

      &:after{
        width: 30px;
        height: 30px;
        background: linear-gradient(to bottom, #44bb4f 0%,#138f19 100%);
        z-index: 1;
        border-radius: 50%;
        @include posXY;
        content: '';
        opacity: 0;
      }
    }

    input{
      background-color: transparent;
      border: none;
      outline: none;
      height: 30px;
      line-height: 30px;
      border-left: 1px solid #33537b;
      width: 50%;
      padding: 0 25px;
      font-family: $d;
      font-weight: 400;
      font-size: 16px;
      color: #fff;

      @include phones-hor{
        font-size: 12px;
        padding: 0px 10px;
      }

      &:valid + .sign-input-wrap{
        .sign-input__check{
          svg, &:after{
            opacity: 1;
          }
        }
        span{
          color: #fff;
  
          svg{
            fill: $orange;
          }
        }
      }
      &:focus + .sign-input-wrap{
        .sign-input__check{
          svg, &:after{
            opacity: 1;
          }
        }
        span{
          color: #fff;
  
          svg{
            fill: $orange;
          }
        }
      }
    }

    span{
      position: relative;
      padding-left: 65px;
      color: #33537b;
      transition: all .3s ease;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 13px;

      @include phones-hor{
        padding-left: 30px;
        font-size: 10px;
      }
      
      svg{
        @include posY;
        left: 25px;
        fill: #33537b;
        width: 25px;
        height: 25px;
        transition: all .3s ease;

        @include phones-hor{
          left: 5px;
          width: 15px;
          height: 15px;
        }
      }
    }

    &.active{
      .sign-input__check{
        svg, &:after{
          opacity: 1;
        }
      }
      span{
        color: #fff;

        svg{
          fill: $orange;
        }
      }
    }

    &_sponsor{
      transition: all .3s ease;
      border: none;
      background-color: #01234d;
      flex-direction: row;

      input{
        font-weight: 700;
        text-transform: uppercase;
        font-size: 13px;
        color: #33537b;
        text-align: center;
        width: 100%;
        border: none;
      }

      ::placeholder{
        color: #33537b;
      }
    }
  }

  &-bot{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @include tablets{
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .main-btn{
      margin-right: 30px;

      @include tablets{
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  &-check{
    cursor: pointer;
    display: inline-block;

    input{
      display: none;

      &:checked + span svg{
        opacity: 1;
      }
    }

    span{
      position: relative;
      padding-left: 60px;
      display: inline-block;
      color: #33537b;
      font-size: 16px;
      font-weight: 400;
      font-family: $d;
      text-decoration: none;

      a{
        color: $orange;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }

      svg{
        @include posY;
        width: 15px;
        height: 15px;
        left: 20px;
        transform: translate(-50%, -50%);
        fill: $orange;
        opacity: 0;
        transition: all .3s ease;
      }

      &:after{
        @include posY;
        left: 0;
        border: 1px solid #33537b;
        content: '';
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }
    }
  }

  &-capch{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @include tablets{
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

        

    .sign-input{
      width: calc(50% - 7px);

      @include tablets{
        width: 100%;
      }

      span{
        width: 50%;
        text-align: center;
        padding: 0 5px;

        img{
          max-width: 100%;
        }
      }
    }
  }
}