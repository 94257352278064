%clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

//position

@mixin posXY{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin posX{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin posY{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// bg

@mixin bgCover($bg){
  background-image: url('../img/' + $bg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@mixin grP($deg){
  background: linear-gradient($deg, rgba(67, 54, 182, 1) 0%, rgba(164, 78, 208, 1) 100%);
}

//media

@mixin desktop2300 {
  @media (max-width: 2300px) {
    @content;
  }
}

@mixin desktop1700 {
  @media (max-width: 1700px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin phones-hor {
  @media (max-width: 568px) {
    @content;
  }
}

@mixin phones {
  @media (max-width: 480px) {
    @content;
  }
}