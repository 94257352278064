@keyframes statRotate{
  0%{
    transform: translate(-50%, -50%) rotate(0deg);
  }

  80%{
    transform: translate(-50%, -50%) rotate(360deg);
  }

  100%{
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes statScale{
  0%{
    transform: scale(0);
  }

  80%{
    transform: scale(0);
  }

  100%{
    transform: scale(1);
  }
}

@keyframes invest-wallets{
  0%{
    top: 150px;
  }

  50%{
    top: 110px;
  }

  100%{
    top: 150px;
  }
}

@keyframes walletLeft{
  0%{
    right: 150px;
    bottom: 0;
    opacity: 0;
  }

  50%{
    right: 100px;
    bottom: 200px;
    opacity: 1;
  }

  100%{
    right: 150px;
    bottom: 400px;
    opacity: 0;
  }
}

@keyframes walletRight{
  0%{
    right: 100px;
    bottom: 0;
    opacity: 0;
  }

  50%{
    right: 150px;
    bottom: 200px;
    opacity: 1;
  }

  100%{
    right: 100px;
    bottom: 400px;
    opacity: 0;
  }
}

@keyframes pulse{
  0%{
    opacity: 0;
  }

  50%{
    opacity: 1;
  }

  100%{
    opacity: 0;
  }
}


@keyframes road1{
  0%{
    bottom: 18%;
    left: 21%;
    opacity: 0;
  }

  25%{
    bottom: 26%;
    left: 33%;
    opacity: 0.5;
  }

  35%{
    bottom: 31%;
    left: 39%;
    opacity: 1;
  }

  55%{
    bottom: 40%;
    left: 46%;
    opacity: 1;
  }

  75%{
    bottom: 55%;
    left: 52%;
    opacity: 1;
  }

  85%{
    bottom: 63%;
    left: 58%;
    opacity: 1;
  }

  95%{
    bottom: 63%;
    left: 61%;
    opacity: 0.5;
  }

  100%{
    bottom: 57%;
    left: 67%;
    opacity: 0;
  }
}
@keyframes road2{
  0%{
    bottom: 14%;
    left: 24%;
    opacity: 0;
  }

  25%{
    bottom: 25%;
    left: 41%;
    opacity: 0.5;
  }

  55%{
    bottom: 37%;
    left: 50%;
    opacity: 1;
  }

  65%{
    bottom: 41%;
    left: 56%;
    opacity: 1;
  }

  75%{
    bottom: 43%;
    left: 65%;
    opacity: 1;
  }

  85%{
    bottom: 45%;
    left: 71%;
    opacity: .5;
  }

  100%{
    bottom: 56%;
    left: 88%;
    opacity: 0;
  }
}

@keyframes road3{
  0%{
    bottom: 10%;
    left: 29%;
    opacity: 0;
  }

  15%{
    bottom: 15%;
    left: 36%;
    opacity: .5;
  }

  65%{
    bottom: 29%;
    left: 51%;
    opacity: 1;
  }

  75%{
    bottom: 33%;
    left: 57%;
    opacity: 1;
  }

  85%{
    bottom: 35%;
    left: 67%;
    opacity: 1;
  }

  100%{
    bottom: 48%;
    left: 87%;
    opacity: 0;
  }
}
@keyframes road4{
  0%{
    bottom: 8%;
    left: 40%;
    opacity: 0;
  }

  15%{
    bottom: 25%;
    left: 66%;
    opacity: .5;
  }

  25%{
    bottom: 30%;
    left: 71%;
    opacity: 1;
  }

  55%{
    bottom: 42%;
    left: 78%;
    opacity: 1;
  }

  75%{
    bottom: 52%;
    left: 83%;
    opacity: 1;
  }
  85%{
    bottom: 53%;
    left: 86%;
    opacity: .5;
  }

  100%{
    bottom: 47%;
    left: 92%;
    opacity: 0;
  }
}
@keyframes road5{
  0%{
    left: 34%;
    bottom: 0%;
    opacity: 0;
  }

  15%{
    left: 70%;
    bottom: 24%;
    opacity: .5;
  }

  65%{
    left: 77%;
    bottom: 33%;
    opacity: 1;
  }

  75%{
    left: 86%;
    bottom: 38%;
    opacity: 1;
  }

  85%{
    left: 91%;
    bottom: 39%;
    opacity: .5;
  }

  100%{
    left: 95%;
    bottom: 41%;
    opacity: 0;
  }
}
@keyframes road6{
  0%{
    left: 43%;
    bottom: 0%;
    opacity: 0;
  }

  15%{
    left: 68%;
    bottom: 18%;
    opacity: .5;
  }

  65%{
    left: 79%;
    bottom: 28%;
    opacity: 1;
  }

  75%{
    left: 84%;
    bottom: 31%;
    opacity: 1;
  }

  85%{
    left: 91%;
    bottom: 33%;
    opacity: .5;
  }

  100%{
    left: 95%;
    bottom: 35%;
    opacity: 0;
  }
}


