.about{
  padding: 40px 0 0 0;

  .main-text{
    padding-left: 80px;

    position: relative;

    &:after{
      position: absolute;
      left: 30px;
      top: 0;
      height: 100%;
      width: 1px;
      content: '';
      background-color: #0c3566;
    }
  }

  &-text{
    margin-bottom: 20px;
    p{
      font-size: 20px;
      color: #fff;
      font-weight: 500;
      // font-style: italic;
      line-height: 1.5;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &-video{
    position: relative;
    padding-left: 80px;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    color: $orange;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 40px;

    @include laptop{
      margin-bottom: 50px;
    }

    &:hover{
      .about-video__icon{
        border-color: $blue;

        &:after{
          border-color: $orange;
        }
        &:before{
          height: 0;
        }
  
        svg{
          fill: $orange;
        }
      }
    }

    &__icon{
      @include posY;
      left: 0;
      width: 56px;
      height: 56px;
      border: 1px solid $orange;
      border-radius: 50%;
      transition: all .3s ease;

      &:after{
        @include posXY;
        border: 1px solid $blue-l;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 50%;
        content: '';
        transition: all .3s ease;
      }

      &:before{
        transition: all .3s ease;
        bottom: 100%;
        @include posX;
        height: 25px;
        width: 1px;
        background-color: $orange;
        content: '';
      }

      svg{
        @include posXY;
        width: 35px;
        height: 35px;
        fill: #0c3566;
        transition: all .3s ease;
      }
    }
  }

  &-img{
    position: relative;
    width: 100%;
    height: 340px;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    border-radius: 8px;
    z-index: 2;

    &:hover{
      .about-video__icon{
        border-color: $blue;

        &:after{
          border-color: $orange;
        }
        &:before{
          height: 0;
        }
  
        svg{
          fill: $orange;
        }
      }
    }

    .about-video{
      @include posXY;
    }


    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 8px;
    }

    .main-btn{
      @include posX;
      bottom: 0;
      transform: translate(-50%, 50%);
      z-index: 3;
    }

    &_page{
      height: 480px;
      margin: 0 auto 50px auto;

      @include tablets{
        height: 320px;
      }
    }
  }

  &-list{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;

    @include tablets{
      padding: 0;
    }
  }

  &-item{
    width: 100%;
    border-right: 1px solid #0c3566;
    padding: 45px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    @include phones-hor{
      padding: 45px 10px;
    }

    &:first-child{
      border-left: 1px solid #0c3566;
    }

    &:nth-child(1){
      .about-item__title{
        color: #df2c33;
      }
    }
    &:nth-child(2){
      .about-item__title{
        color: #038167;
      }
    }
    &:nth-child(3){
      .about-item__title{
        color: #215ca0;
      }
    }

    &__num{
      color: #0c3566;
      font-weight: 900;
      font-size: 70px;
      font-family: $d;
      margin-bottom: 20px;

      @include phones-hor{
        font-size: 40px;
      }
    }

    &__img{
      width: 100%;
      text-align: center;
      @include posX;
      top: 80px;
      padding: 0 10px;

      @include phones-hor{
        top: 70px;
      }

      img{
        max-width: 100%;
      }
    }

    &__title{
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 14px;
      width: 100%;

      @include phones-hor{
        font-size: 12px;
      }
    }

    p{
      font-size: 11px;
      color: #365f8f;
      font-weight: 400;
      line-height: 1.3;

      @include phones-hor{
        font-size: 10px;
      }
    }
  }

  &-cert{
    margin-bottom: 80px;

    @include tablets{
      margin-bottom: 40px;
    }

    .main-text{
      padding-left: 30px;
      margin-bottom: 20px;
    }

    &__box{
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 50px 180px 25px 30px;
      width: 100%;
      background-image: url(../img/about-cert-bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      position: relative;
      cursor: pointer;
      max-width: 470px;
      @include phones{
        padding: 35px 140px 8px 30px;
      }

      .cert-num{
        @include phones{
          font-size: 20px;
        }
      }

      .cert-text p{
        @include phones{
          font-size: 10px;
          color: $blue-l;
        }
      }

      &:hover{
        span{
          color: $orange;
        }
      }

      span{
        position: absolute;
        left: 89%;
        top: 60%;
        transform: translate(-50%, -50%) rotate(-57deg);
        color: #2c5382;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        transition: all .3s ease;
      }

      .cert-text{
        margin-bottom: 0;
        
        p{
          color: #277ee6;
        }
      }
    }
  }

  &-activity{
    width: 100%;

    @include laptop{
      margin-bottom: 40px;
    }

    &__item{
      width: 100%;
    }

    &__text{
      width: 100%;
      color: #fff;
      position: relative;
      padding-top: 30px;

      span{
        position: absolute;
        left: -30px;
        top: -30px;
        font-family: $dc;
        font-weight: 500;
        color: #0c3566;
        z-index: 1;
        font-size: 88px;
      }

      b{
        font-weight: 800;
      }
      
      p{
        width: 100%;
        margin-bottom: 30px;
        position: relative;
        z-index: 2;
        line-height: 1.5;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    &__links{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__link{
      width: 100%;
      padding: 15px 100px 15px 25px;
      background-color: transparent;
      color: #315988;
      font-weight: 700;
      font-size: 16px;
      position: relative;
      transition: all .3s ease;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-height: 90px;

      &.active, &:hover{
        color: #fff;

        &:after{
          height: 3px;
          background-color: $orange;
        }

        &:before{
          background-color: $orange;
        }
      }

      &:before{
        @include posY;
        right: 25px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #0d315d;
        content: '';
        transition: all .3s ease;
      }

      &:after{
        width: 100%;
        height: 1px;
        transition: all .3s ease;
        bottom: 0;
        left: 0;
        position: absolute;
        content: '';
        background-color: #0d315d;
      }
    }
  }

  &-info{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;

    &__icon{
      @include posXY;
      width: 60px;
      height: 60px;
      border-radius: 8px;
      border: 2px solid #012249;
      background-color: #002654;
      top: calc(50% - 10px);

      img{
        @include posXY;
        width: 32px;
        height: 32px;
      }
    }

    &__item{
      margin-bottom: 20px;
      width: calc(50% - 15px);
      padding: 20px 20px;
      border-radius: 8px;
      background-color: #012249;

      img{
        max-width: 100%;
        margin-bottom: 15px;
      }

      p{
        font-family: $p;
        font-size: 13px;
        font-weight: 300;
        color: #4c729f;
      }
    }
  }

  &-btn{
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
  }

  &-stat{
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    @include tablets{
      flex-wrap: wrap;
    }
    
    &__head{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;

      .main-title{
        margin-bottom: 0;
        margin-right: 20px;
      }

      p{
        color: #4c729f;
        font-family: $p;
        font-weight: 400;
        font-size: 14px;
        padding-left: 20px;
        border-left: 1px solid #0d315d;
        font-style: italic;
      }
    }

    &__item{
      width: calc(25% + 20px);
      padding: 25px 25px 25px 45px;
      border-radius: 8px;
      box-shadow: 12px 0px 16px 0px rgba(0, 0, 0, 0.07);
      background-color: #012249;
      margin-right: -20px;
      z-index: 2;
      position: relative;

      @include tablets{
        width: calc(50% + 10px);
      }

      &:nth-child(1){
        z-index: 4;
        padding: 25px;
        width: 25%;

        @include tablets{
          width: calc(50% + 10px);
        }
      }
      &:nth-child(2){
        z-index: 3;
      }
      &:nth-child(3){
        z-index: 2;
      }
      &:nth-child(4){
        z-index: 1;
      }

      &:nth-child(3n+3){
        @include tablets{
          padding-left: 25px;
        }
      }
    }

    &__box{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 25px;

      @include phones{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      span{
        max-width: 50%;
        font-family: $dc;
        font-size: 24px;
        font-weight: 900;
        color: #3579ca;
        padding-right: 15px;

        @include phones{
          margin-bottom: 10px;
        }
      }

      p{
        color: #2a4f82;
        font-family: $p;
        font-weight: 400;
        font-size: 13px;
      }
    }

    &__bar{
      width: 100%;
      border-radius: 10px;
      height: 4px;
      position: relative;
      background-color: #102f54;

      &-line{
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        background-color: $orange;
      }
    }
  }
}