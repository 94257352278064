.affil{
  background-image: url(../img/affil-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;
  padding: 0 0 20px 0;

  @include desktop{
    background-image: none;
  }

  .main-btn{
    margin-bottom: 45px;
  }

  &-title{
    margin-bottom: 25px;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;

   &__line{
     position: absolute;

     @include desktop{
       display: none;
     }

     &:after{
       height: 1px;
       background-color: #315078;
       content: '';
       @include posY;
       right: 0;
       width: 100%;
     }

     &:before{
       width: 6px;
       height: 6px;
       background-color: #315078;
       content: '';
       border-radius: 50%;
       @include posY;
       right: 0;
     }
   }

    &_blue{
      color: $blue-l;

      .affil-title__line_1{
        width: 60px;
        position: absolute;
        right: calc(100% + 10px);
        transform: translateY(-50%);
        top: 50%;
      }

      .affil-title__line_2{
        width: 110px;
        position: absolute;
        right: calc(100% + 70px);
        top: 50%;
        transform: translateY(-50%) rotate(62deg);
        transform-origin: 100% 50%;

        &:before{
          left: 0;
          right: auto;
        }
      }
    }

    &_orange{
      color: $orange;

      .affil-title__line_1{
        width: 30px;
        position: absolute;
        right: calc(100% + 10px);
        transform: translateY(-50%);
        top: 50%;
      }

      .affil-title__line_2{
        width: 110px;
        position: absolute;
        right: calc(100% + 40px);
        top: 50%;
        transform: translateY(-50%) rotate(-77deg);
        transform-origin: 100% 50%;

        &:before{
          left: 0;
          right: auto;
        }
      }
    }
  }

  &-lvl{
    width: 400px;
    height: 400px;
    margin: 0 auto 200px auto;
    position: relative;
    background-image: url(../img/affil-circle.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @include phones-hor{
      background-image: none;
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto 100px auto;
    }
    
    &__item{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 20px;
      position: absolute;

      @include phones-hor{
        position: relative;
        width: calc(100% / 3);
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
      }

      &:nth-child(1){
        left: -53px;
        top: -16px;
      }

      &:nth-child(2){
        right: -22px;
        top: -31px;
      }

      &:nth-child(3){
        left: 80px;
        bottom: -70px;
      }
      
      &:nth-child(4){
        left: 95px;
        top: 150px;
      }

      &:nth-child(5){
        top: 90px;
        right: 130px;
      }

      &:nth-child(6){
        right: 95px;
        bottom: 133px;
      }

      &:after{
        height: 100%;
        width: 1px;
        background-color: rgba(#fff, .08);
        left: 0;
        top: 0;
        position: absolute;
        content: '';
      }

      &:before{
        bottom: 0;
        left: 0;
        position: absolute;
        content: '';
        height: 10px;
        width: 1px;
        background-color: $orange;
      }

      span{
        font-size: 57px;
        color: $orange;
        margin-bottom: 10px;
        font-family: $dc;
        font-weight: 900;

        @include phones-hor{
          font-size: 35px;
        }
      }

      p{
        color: #2c4d76;
        font-size: 12px;
        text-transform: uppercase;
      }

      &_in{

        &:before{
          background-color: $blue-l;
        }
        span{
          font-size: 41px;
          color: $blue-l;

          @include phones-hor{
            font-size: 35px;
          }
        }

        p{
          font-size: 8px;
        }
      }
    }
  }

  &-pay{
    position: relative;
    width: 100%;
    padding-left: 145px;
    margin-top: -45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include laptop{
      padding-left: 100px;
    }

    @include tablets{
      margin-top: 0;
      padding-left: 0;
      align-items: center;
      padding-top: 100px;
    }

    &__icon{
      @include posY;
      left: 0;
      border: 6px solid #0c3566;
      background-color: $orange;
      width: 45px;
      height: 45px;
      border-radius: 50%;

      @include tablets{
        @include posX;
        top: 0;
        transform: translateX(-50%) rotate(90deg);
      }

      svg{
        @include posXY;
        fill: #0c3566;
        width: 15px;
        height: 15px;
      }
    }
    
    .main-title{
      margin-bottom: 5px;
    }

    span{
      text-transform: uppercase;
      color: $orange;
      font-size: 10px;
      font-weight: 400;
    }
  }

  &-item{
    padding-left: 65px;
    position: relative;
    padding-top: 5px;

    .affil-title{
      &:after{
        display: none;
      }
    }

    &__num{
      position: absolute;
      left: 0;
      top: 0;
      width: 46px;
      height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 46px;
      background-color: $blue-l;
      border-radius: 8px;
      font-family: $dc;
      font-weight: 900;
      font-size: 22px;
      color: #0c3566;
    }

    &__icon{
      margin-bottom: 25px;
      max-width: 50px;

      img{
        max-width: 100%;
      }
    }

    &_blue{
      .affil-item__num{
        background-color: $blue-l;
      }
    }

    &_orange{
      .affil-item__num{
        background-color: $orange;
      }
    }
  }
}

.calc{
  margin-top: 20px;
  margin-bottom: 90px;
  width: 100%;
  position: relative;
  z-index: 10;

  .calc-amount{
    &:after{
      bottom: 5px;
      width: 90%;
      height: 1px;
      @include posX;
      background-color: $orange;
      content: '';
    }
  }

  &-info{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    @include tablets{
      justify-content: center;
      margin-bottom: 20px;
    }

    &__item{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 20px;
      position: relative;
      margin-right: 40px;

      &:last-child{
        margin-right: 0;
      }

      &:after{
        position: absolute;
        left: 0;
        height: 30px;
        background-color: $orange;
        width: 1px;
        content: '';
        top: 0;
      }

      &:before{
        left: 0;
        top: 0;
        height: 100%;
        width: 1px;
        position: absolute;
        content: '';
        background-color: rgba(#fff, .08);
      }
    }

    &__val{
      font-family: $dc;
      font-weight: 900;
      font-size: 28px;
      color: #fff;
      margin-bottom: 15px;
      margin-bottom: 15px;

      span{
        color: $orange;
      }
    }

    &__text{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      p{
        color: $orange;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 5px;
      }

      span{
        font-size: 10px;
        color: #34537a;
        text-transform: uppercase;
      }
    }
  }

  &-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;

    @include tablets{
      margin-bottom: 0;
    }

    @include phones{
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__title{
      position: relative;
      padding-left: 40px;
      color: $orange;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;

      svg{
        @include posY;
        left: 10px;
        top: 70%;
        fill: #25466f;
        transform: translateY(-50%) rotate(90deg) scaleY(-1);
        width: 15px;
        height: 15px;
      }
    }
  }

  &-amount{
    max-width: 260px;
    width: 100%;
    display: inline-block;
    position: relative;

    ::placeholder{
      color: $orange;
    }

    @include desktop{
      max-width: calc(50% - 15px) ;
    }

    @include phones{
      width: 100%;
      margin-bottom: 15px;
      max-width: 100%;
    }

    input, textarea{
      height: 60px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #143761;
      background-color: rgba(#fff, .05);
      outline: none;
      line-height: 60px;
      padding: 0 15px;
      font-weight: 800;
      font-size: 16px;
      color: $orange;
    }

    textarea{
      height: 140px;
      line-height: 16px;
      font-weight: 400;
      color: #fff;
      padding-top: 10px;
      resize: none;
    }

    &_white{
      input{
        font-weight: 400;
        color: #fff;
      }
    }

    &_contacts{
      margin-bottom: 20px;
      max-width: 100%;

      input, textarea{
        background-color: transparent;
      }
    }
  }

  &-select{
    max-width: 360px;
    width: 100%;

    @include desktop{
      max-width: calc(50% - 15px);
    }

    @include phones{
      width: 100%;
      max-width: 100%;
    }

    .jq-selectbox{
      width: 100%;

      li{
        font-family: $c;
        font-weight: 600;
        color: #b7b7b7;
        position: relative;
        padding: 20px 40px 20px 20px;

        &:after{
          content: '';
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(50%, -50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #b7b7b7;
        }

        &.sel{
          color: #000;

          &:after{
            background-color: $orange;
          }
        }
      }

      &__select{
        height: 60px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #143761 !important;
        background: rgba(#fff, .05) !important;
        outline: none;
        line-height: 60px;
        padding: 0 15px;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        text-shadow: none !important;
        box-shadow: none !important;
      }

      &__trigger{
        border: none !important;

        &:after{
          @include posXY;
          content: '>';
          transform: translate(-50%, -50%) rotate(90deg) scaleY(1.4);
          font-size: 10px;
          text-transform: uppercase;
          color: rgba(#fff, .3);

        }

        &-arrow{
          display: none;
        }
      }

      &__dropdown{
        border: none;
        overflow: hidden;
      }
    }
  }
}

.refs{
  &-lvl{
    width: 100%;
    position: relative;
    height: 220px;

    @include desktop{
      height: auto !important;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
    }

    @include tablets{
      margin-bottom: 40px;
      padding-left: 60px;
    }

    @include phones-hor{
      padding-left: 0;
    }

    

    &__item{
      height: 100%;
      background-image: url(../img/partners-bg.png);
      background-repeat: no-repeat;
      background-size: contain;
      width: 60vw;
      position: absolute;
      padding: 30px 0px 30px 80px;

      @include desktop{
        position: static !important;
        background: transparent !important;
        height: auto !important;
        padding: 0 !important;
        left: auto !important;
        bottom: auto !important;
        top: auto !important;
        width: auto !important;
        margin-right: 20px;
      }

      &:nth-child(1){
        z-index: 3;
        left: 220px;

        .refs-lvl__item-val{
          font-size: 80px;
          line-height: 80px;
        }
      }
      &:nth-child(2){
        z-index: 2;
        left: 120px;
        height: calc(100% - 50px);
        opacity: 0.45;

        .refs-lvl__item-val{
          font-size: 47px;
          padding-left: 20px;
          line-height: 55px;
        }
      }
      &:nth-child(3){
        z-index: 1;
        left: 0;
        height: calc(100% - 90px);
        opacity: 0.15;
        padding: 20px 0px 20px 60px;

        .refs-lvl__item-val{
          font-size: 33px;
          padding-left: 20px;
          line-height: 45px;
        }
      }

      &-title{
        margin-bottom: 20px;
        text-transform: uppercase;
        color: #fff;
        font-size: 10px;
        font-weight: 800;

        @include desktop{
          padding: 0 !important;
        }
      }

      &-val{
        padding-left: 50px;
        font-weight: 900;
        color: $blue-l;
        font-family: $dc;
        

        @include desktop{
          padding: 0 !important;
          
        }
      }
    }

    &_blue{
      .refs-lvl__item{
        bottom: 0;

        .refs-lvl__item-val{
          color: $blue-l;
        }
      }
    }
    &_orange{
      .refs-lvl__item{
        top: 0;
        background-image: url(../img/represent-bg.png);
        &:nth-child(1){
          .refs-lvl__item-title{
            padding-left: 80px;
          }
        }
        .refs-lvl__item-title{
          padding-left: 50px;

         
        }

        .refs-lvl__item-val{
          color: $orange;
        }
      }
    }
  }

  &-title{
    font-weight: 800;
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
  }

  &-text{
    border-left: 1px solid $orange;
    padding: 20px 20px 20px 65px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    background-color: #072d5a;
    margin-bottom: 40px;
    width: 100%;

    p{
      line-height: 1.5;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &-check{
    margin-bottom: 30px;

    p{
      padding-left: 30px;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;
      }

      &:after{
        left: 15px;
        @include posY;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        content: '';
        transform: translate(-50%, -50%);
        background-color: $orange;
      }
    }
  }

  &-subtext{
    margin-bottom: 30px;

    p{
      color: $orange;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &-stat{
    position: relative;
    margin-bottom: 40px;

    &__list{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    &__title{
      margin-bottom: -15px;
      font-weight: 800;
      color: rgba(#fff, .01);
      font-size: 52px;
      text-transform: uppercase;
      text-align: center;
    }

    &__bg{
      @include posXY;
      width: 140px;
      height: 140px;
      background-image: url(../img/refs-stat-bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;

      @include tablets{
        display: none;
      }

      &:after{
        width: 170px;
        height: 170px;
        @include posXY;
        border: 1px solid #24466f;
        border-radius: 50%;
        content: '';
      }

      img{
        @include posXY;
        width: 35px;
        opacity: 0.2;
      }
    }

    &__item{
      width: calc(50% - 60px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 15px 0;
      position: relative;

      @include tablets{
        width: calc(100% / 3 - 10px) !important;
        flex-direction: column !important;
        align-items: center !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center !important;
      }

      &:after{
        display: flex;
        height: 1px;
        background-color: #24466f;
        content: '';
        width: 100%;

        @include tablets{
          display: none;
        }
      }

      &:before{
        height: 1px;
        background-color: #24466f;
        content: '';
        width: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include tablets{
        display: none;
      }
      }

      &:nth-child(even){
        flex-direction: row-reverse;
        text-align: right;

        span{
          margin-right: 0;
          margin-left: 25px;
        }
      }

      &:nth-child(1){
        padding-left: 50px;

        &:before{
          transform: translateY(-50%) rotate(25deg);
          left: 100%;
          transform-origin: 0% 50%;
        }

        span{
          color: #7a5bb3;
          background: radial-gradient(ellipse at center, rgba(30,87,153,0) 0%,rgba(#7a5bb3,1) 100%);
        }
      }
      &:nth-child(2){
        padding-right: 50px;

        &:before{
          transform: translateY(-50%) rotate(-25deg);
          right: 100%;
          transform-origin: 100% 50%;
        }

        span{
          color: #2463af;
          background: radial-gradient(ellipse at center, rgba(30,87,153,0) 0%,rgba(#2463af,1) 100%);
        }
      }
      &:nth-child(3){
        width: calc(50% - 100px);

        &:before{
          display: none;
        }

        span{
          color: #b4bc86;
          background: radial-gradient(ellipse at center, rgba(30,87,153,0) 0%,rgba(#b4bc86,1) 100%);
        }
      }
      &:nth-child(4){
        width: calc(50% - 100px);

        &:before{
          display: none;
        }

        span{
          color: #a95858;
          background: radial-gradient(ellipse at center, rgba(30,87,153,0) 0%,rgba(#a95858,1) 100%);
        }
      }
      &:nth-child(5){
        padding-left: 50px;

        &:before{
          transform: translateY(-50%) rotate(-25deg);
          left: 100%;
          transform-origin: 0% 50%;
        }

        span{
          color: #be9f69;
          background: radial-gradient(ellipse at center, rgba(30,87,153,0) 0%,rgba(#be9f69,1) 100%);
        }
      }
      &:nth-child(6){
        padding-right: 50px;

        &:before{
          transform: translateY(-50%) rotate(25deg);
          right: 100%;
          transform-origin: 100% 50%;
        }

        span{
          color: #59af8b;
          background: radial-gradient(ellipse at center, rgba(30,87,153,0) 0%,rgba(#59af8b,1) 100%);
        }
      }

      span{
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        margin-right: 25px;
        font-family: $d;
        font-weight: 700;
        font-size: 16px;

        @include tablets{
          margin-right: 0 !important;
          margin-left: 0 !important;
          margin-bottom: 20px;
        }


        &:after{
          @include posXY;
          width: calc(100% + 12px);
          height: calc(100% + 12px);
          border-radius: 50%;
          border: 1px solid #24466f;
          content: '';
        }
      }

      p{
        color: #fff;
        font-weight: 800;
        font-size: 16px;
        width: 100%;
        margin-right: 5px;

        @include tablets{
          margin-right: 0 !important;
          font-size: 14px;
        }
      }
    }
  }
}

.contacts-submit{
  position: relative;
  width: 100%;

  .main-btn{
    position: absolute;
    bottom: 0;
    left: 40px;
  }
}
