// Sochi

@font-face {
	font-family: 'Sochi2014';
	src: url('../fonts/Sochi/Sochi2014-Bold.eot');
	src: local('Sochi2014 Bold'), local('Sochi2014-Bold'),
		url('../fonts/Sochi/Sochi2014-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Sochi/Sochi2014-Bold.woff') format('woff'),
		url('../fonts/Sochi/Sochi2014-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Sochi2014';
	src: url('../fonts/Sochi/Sochi2014-Medium.eot');
	src: local('Sochi2014 Medium'), local('Sochi2014-Medium'),
		url('../fonts/Sochi/Sochi2014-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Sochi/Sochi2014-Medium.woff') format('woff'),
		url('../fonts/Sochi/Sochi2014-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Sochi2014';
	src: url('../fonts/Sochi/Sochi2014-Light.eot');
	src: local('Sochi2014 Light'), local('Sochi2014-Light'),
		url('../fonts/Sochi/Sochi2014-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Sochi/Sochi2014-Light.woff') format('woff'),
		url('../fonts/Sochi/Sochi2014-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}


// Circe

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe/Circe-Regular.eot');
	src: local('Circe'), local('Circe-Regular'),
		url('../fonts/Circe/Circe-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Circe/Circe-Regular.woff') format('woff'),
		url('../fonts/Circe/Circe-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe/Circe-ExtraLight.eot');
	src: local('Circe ExtraLight'), local('Circe-ExtraLight'),
		url('../fonts/Circe/Circe-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Circe/Circe-ExtraLight.woff') format('woff'),
		url('../fonts/Circe/Circe-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe/Circe-Thin.eot');
	src: local('Circe Thin'), local('Circe-Thin'),
		url('../fonts/Circe/Circe-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Circe/Circe-Thin.woff') format('woff'),
		url('../fonts/Circe/Circe-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe/Circe-Light.eot');
	src: local('Circe Light'), local('Circe-Light'),
		url('../fonts/Circe/Circe-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Circe/Circe-Light.woff') format('woff'),
		url('../fonts/Circe/Circe-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe/Circe-Bold.eot');
	src: local('Circe Bold'), local('Circe-Bold'),
		url('../fonts/Circe/Circe-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Circe/Circe-Bold.woff') format('woff'),
		url('../fonts/Circe/Circe-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('../fonts/Circe/Circe-ExtraBold.eot');
	src: local('Circe ExtraBold'), local('Circe-ExtraBold'),
		url('../fonts/Circe/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Circe/Circe-ExtraBold.woff') format('woff'),
		url('../fonts/Circe/Circe-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}


// DIN

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-Medium.eot');
	src: local('DIN Pro Medium'), local('DINPro-Medium'),
		url('../fonts/Dinpro/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-Medium.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-BlackItalic.eot');
	src: local('DIN Pro Black Italic'), local('DINPro-BlackItalic'),
		url('../fonts/Dinpro/DINPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-BlackItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}



@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-MediumItalic.eot');
	src: local('DIN Pro Medium Italic'), local('DINPro-MediumItalic'),
		url('../fonts/Dinpro/DINPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-MediumItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-Bold.eot');
	src: local('DIN Pro Bold'), local('DINPro-Bold'),
		url('../fonts/Dinpro/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-Bold.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-Light.eot');
	src: local('DIN Pro Light'), local('DINPro-Light'),
		url('../fonts/Dinpro/DINPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-Light.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-Italic.eot');
	src: local('DIN Pro Italic'), local('DINPro-Italic'),
		url('../fonts/Dinpro/DINPro-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-Italic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro.eot');
	src: local('DINPro'), local('DINPro'),
		url('../fonts/Dinpro/DINPro.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro.woff') format('woff'),
		url('../fonts/Dinpro/DINPro.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-Black.eot');
	src: local('DIN Pro Black'), local('DINPro-Black'),
		url('../fonts/Dinpro/DINPro-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-Black.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-LightItalic.eot');
	src: local('DIN Pro Light Italic'), local('DINPro-LightItalic'),
		url('../fonts/Dinpro/DINPro-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-LightItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'DINPro';
	src: url('../fonts/Dinpro/DINPro-BoldItalic.eot');
	src: local('DIN Pro Bold Italic'), local('DINPro-BoldItalic'),
		url('../fonts/Dinpro/DINPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-BoldItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

// Cond


@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedBlackItalic.eot');
	src: local('DIN Pro Condensed Black Italic'), local('DINPro-CondensedBlackItalic'),
		url('../fonts/Dinpro/DINPro-CondensedBlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedBlackItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedBlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedMedium.eot');
	src: local('DIN Pro Condensed Medium'), local('DINPro-CondensedMedium'),
		url('../fonts/Dinpro/DINPro-CondensedMedium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedMedium.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedMediumItalic.eot');
	src: local('DIN Pro Condensed Medium Italic'), local('DINPro-CondensedMediumItalic'),
		url('../fonts/Dinpro/DINPro-CondensedMediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedMediumItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedMediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedBlack.eot');
	src: local('DIN Pro Condensed Black'), local('DINPro-CondensedBlack'),
		url('../fonts/Dinpro/DINPro-CondensedBlack.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedBlack.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedBlack.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedBold.eot');
	src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
		url('../fonts/Dinpro/DINPro-CondensedBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedBold.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedBold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedBoldItalic.eot');
	src: local('DIN Pro Condensed Bold Italic'), local('DINPro-CondensedBoldItalic'),
		url('../fonts/Dinpro/DINPro-CondensedBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedBoldItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedBoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedRegular.eot');
	src: local('DIN Pro Condensed Regular'), local('DINPro-CondensedRegular'),
		url('../fonts/Dinpro/DINPro-CondensedRegular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedRegular.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedRegular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedItalic.eot');
	src: local('DIN Pro Condensed Italic'), local('DINPro-CondensedItalic'),
		url('../fonts/Dinpro/DINPro-CondensedItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedItalic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedLightItalic.eot');
	src: local('DIN Pro Condensed Light Italic'), local('DINPro-CondensedLightItalic'),
		url('../fonts/Dinpro/DINPro-CondensedLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedLightItalic.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedLightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'DINProCond';
	src: url('../fonts/Dinpro/DINPro-CondensedLight.eot');
	src: local('DIN Pro Condensed Light'), local('DINPro-CondensedLight'),
		url('../fonts/Dinpro/DINPro-CondensedLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Dinpro/DINPro-CondensedLight.woff') format('woff'),
		url('../fonts/Dinpro/DINPro-CondensedLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

//proxima

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-LightIt.eot');
	src: local('Proxima Nova Condensed Light Italic'), local('ProximaNovaCond-LightIt'),
		url('../fonts/Proxima/ProximaNovaCond-LightIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-LightIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('../fonts/Proxima/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-LightIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-Regular.eot');
	src: local('Proxima Nova Condensed Regular'), local('ProximaNovaCond-Regular'),
		url('../fonts/Proxima/ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-Regular.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-Extrabld.eot');
	src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
		url('../fonts/Proxima/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-Extrabld.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-Black.eot');
	src: local('Proxima Nova Condensed Black'), local('ProximaNovaCond-Black'),
		url('../fonts/Proxima/ProximaNovaCond-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-Black.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-ExtrabldIt.eot');
	src: local('Proxima Nova Condensed Extrabold Italic'), local('ProximaNovaCond-ExtrabldIt'),
		url('../fonts/Proxima/ProximaNovaCond-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-ExtrabldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('../fonts/Proxima/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-Regular.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-Semibold.eot');
	src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
		url('../fonts/Proxima/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-Semibold.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNovaCn Th';
	src: url('../fonts/Proxima/ProximaNovaExCn-Thin.eot');
	src: local('Proxima Nova Extra Condensed Thin'), local('ProximaNovaExCn-Thin'),
		url('../fonts/Proxima/ProximaNovaExCn-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-Thin.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-Semibold.eot');
	src: local('Proxima Nova Condensed Semibold'), local('ProximaNovaCond-Semibold'),
		url('../fonts/Proxima/ProximaNovaCond-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-Semibold.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNovaCn Lt';
	src: url('../fonts/Proxima/ProximaNovaExCn-SemiboldIt.eot');
	src: local('Proxima Nova Extra Condensed Semibold Italic'), local('ProximaNovaExCn-SemiboldIt'),
		url('../fonts/Proxima/ProximaNovaExCn-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-SemiboldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNovaCn Lt';
	src: url('../fonts/Proxima/ProximaNovaExCn-Semibold.eot');
	src: local('Proxima Nova Extra Condensed Semibold'), local('ProximaNovaExCn-Semibold'),
		url('../fonts/Proxima/ProximaNovaExCn-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-Semibold.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-ThinIt.eot');
	src: local('Proxima Nova Thin Italic'), local('ProximaNova-ThinIt'),
		url('../fonts/Proxima/ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-ThinIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-Thin.eot');
	src: local('Proxima Nova Condensed Thin'), local('ProximaNovaCond-Thin'),
		url('../fonts/Proxima/ProximaNovaCond-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-Thin.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-RegularIt.eot');
	src: local('Proxima Nova Condensed Regular Italic'), local('ProximaNovaCond-RegularIt'),
		url('../fonts/Proxima/ProximaNovaCond-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-RegularIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-RegularIt.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNovaCn Th';
	src: url('../fonts/Proxima/ProximaNovaExCn-ThinIt.eot');
	src: local('Proxima Nova Extra Condensed Thin Italic'), local('ProximaNovaExCn-ThinIt'),
		url('../fonts/Proxima/ProximaNovaExCn-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-ThinIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-SemiboldIt.eot');
	src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
		url('../fonts/Proxima/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-SemiboldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-RegularIt.eot');
	src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
		url('../fonts/Proxima/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-RegularIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-Extrabld.eot');
	src: local('Proxima Nova Condensed Extrabold'), local('ProximaNovaCond-Extrabld'),
		url('../fonts/Proxima/ProximaNovaCond-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-Extrabld.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNovaCn Th';
	src: url('../fonts/Proxima/ProximaNovaExCn-ExtrabldIt.eot');
	src: local('Proxima Nova Extra Condensed Extrabold Italic'), local('ProximaNovaExCn-ExtrabldIt'),
		url('../fonts/Proxima/ProximaNovaExCn-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-ExtrabldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNovaCn Bl';
	src: url('../fonts/Proxima/ProximaNovaExCn-BlackIt.eot');
	src: local('Proxima Nova Extra Condensed Black Italic'), local('ProximaNovaExCn-BlackIt'),
		url('../fonts/Proxima/ProximaNovaExCn-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-BlackIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNovaCn Lt';
	src: url('../fonts/Proxima/ProximaNovaExCn-LightIt.eot');
	src: local('Proxima Nova Extra Condensed Light Italic'), local('ProximaNovaExCn-LightIt'),
		url('../fonts/Proxima/ProximaNovaExCn-LightIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-LightIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-ThinIt.eot');
	src: local('Proxima Nova Condensed Thin Italic'), local('ProximaNovaCond-ThinIt'),
		url('../fonts/Proxima/ProximaNovaCond-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-ThinIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNovaCn Lt';
	src: url('../fonts/Proxima/ProximaNovaExCn-Light.eot');
	src: local('Proxima Nova Extra Condensed Light'), local('ProximaNovaExCn-Light'),
		url('../fonts/Proxima/ProximaNovaExCn-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-Light.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-BoldIt.eot');
	src: local('Proxima Nova Condensed Bold Italic'), local('ProximaNovaCond-BoldIt'),
		url('../fonts/Proxima/ProximaNovaCond-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-BoldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-BoldIt.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNovaCn Th';
	src: url('../fonts/Proxima/ProximaNovaExCn-Extrabld.eot');
	src: local('Proxima Nova Extra Condensed Extrabold'), local('ProximaNovaExCn-Extrabld'),
		url('../fonts/Proxima/ProximaNovaExCn-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-Extrabld.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNovaCn Rg';
	src: url('../fonts/Proxima/ProximaNovaExCn-BoldIt.eot');
	src: local('Proxima Nova Extra Condensed Bold Italic'), local('ProximaNovaExCn-BoldIt'),
		url('../fonts/Proxima/ProximaNovaExCn-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-BoldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-BoldIt.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-Black.eot');
	src: local('Proxima Nova Black'), local('ProximaNova-Black'),
		url('../fonts/Proxima/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-Black.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-Bold.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
		url('../fonts/Proxima/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-Bold.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNovaCn Rg';
	src: url('../fonts/Proxima/ProximaNovaExCn-Bold.eot');
	src: local('Proxima Nova Extra Condensed Bold'), local('ProximaNovaExCn-Bold'),
		url('../fonts/Proxima/ProximaNovaExCn-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-Bold.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNovaT-Thin.eot');
	src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
		url('../fonts/Proxima/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaT-Thin.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaT-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-BoldIt.eot');
	src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
		url('../fonts/Proxima/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-BoldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-BoldIt.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-Bold.eot');
	src: local('Proxima Nova Condensed Bold'), local('ProximaNovaCond-Bold'),
		url('../fonts/Proxima/ProximaNovaCond-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-Bold.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNovaCn Bl';
	src: url('../fonts/Proxima/ProximaNovaExCn-Black.eot');
	src: local('Proxima Nova Extra Condensed Black'), local('ProximaNovaExCn-Black'),
		url('../fonts/Proxima/ProximaNovaExCn-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-Black.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNovaCn Rg';
	src: url('../fonts/Proxima/ProximaNovaExCn-RegularIt.eot');
	src: local('Proxima Nova Extra Condensed Regular Italic'), local('ProximaNovaExCn-RegularIt'),
		url('../fonts/Proxima/ProximaNovaExCn-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-RegularIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-RegularIt.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-Light.eot');
	src: local('Proxima Nova Condensed Light'), local('ProximaNovaCond-Light'),
		url('../fonts/Proxima/ProximaNovaCond-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-Light.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-ExtrabldIt.eot');
	src: local('Proxima Nova Extrabold Italic'), local('ProximaNova-ExtrabldIt'),
		url('../fonts/Proxima/ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-ExtrabldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-BlackIt.eot');
	src: local('Proxima Nova Condensed Black Italic'), local('ProximaNovaCond-BlackIt'),
		url('../fonts/Proxima/ProximaNovaCond-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-BlackIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNovaCn Rg';
	src: url('../fonts/Proxima/ProximaNovaExCn-Regular.eot');
	src: local('Proxima Nova Extra Condensed Regular'), local('ProximaNovaExCn-Regular'),
		url('../fonts/Proxima/ProximaNovaExCn-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaExCn-Regular.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaExCn-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-Light.eot');
	src: local('Proxima Nova Light'), local('ProximaNova-Light'),
		url('../fonts/Proxima/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-Light.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaCn';
	src: url('../fonts/Proxima/ProximaNovaCond-SemiboldIt.eot');
	src: local('Proxima Nova Condensed Semibold Italic'), local('ProximaNovaCond-SemiboldIt'),
		url('../fonts/Proxima/ProximaNovaCond-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNovaCond-SemiboldIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNovaCond-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/Proxima/ProximaNova-BlackIt.eot');
	src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
		url('../fonts/Proxima/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Proxima/ProximaNova-BlackIt.woff') format('woff'),
		url('../fonts/Proxima/ProximaNova-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
