.header{
  border-bottom: 1px solid rgba(#fff, .05);
  padding: 15px 0;
  margin-bottom: 40px;
  position: relative;
  z-index: 20;

  &-contacts{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    padding-top: 15px;

    @include laptop{
      margin-bottom: 20px;
    }

    @include phones-hor{
      display: none;
    }

    // @include tablets{
    //   flex-wrap: wrap;
    // }

    &__item{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      padding-left: 25px;
      padding-right: 5px;

      &.line-top-bg{

        &:after{
          position: absolute;
          height: 20px;
          background-color: #0a3363;
          width: 1px;
          bottom: calc(100% + 15px);
          left: 5px;
          content: '';
          z-index: 1;
        }
        &:before{
          position: absolute;
          height: 6px;
          background-color: $orange;
          width: 1px;
          bottom: calc(100% + 15px);
          left: 5px;
          content: '';
          z-index: 2;
        }
        
      }

      // @include tablets{
      //   width: 50%;
      //   margin-bottom: 10px;
      // }

      & > svg{
        fill: $orange;
        width: 16px;
        height: 16px;
        position: absolute;
        top: -3px;
        left: 0;
      }

      span{
        color: $orange;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      a{
        color: #fff;
        text-decoration: none;
        font-size: 13px;
        font-weight: 400;
        transition: all .3s ease;
        font-family: $p;


        @include laptop{
          font-size: 16px;
        }

        @include tablets{
          font-size: 12px;
        }
        
        &:hover{
          color: $orange;
        }
      }
    }
  }

  &-btns{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    margin-left: 15px;

    .main-btn{
      height: 40px;
      padding: 0 15px;
      font-size: 16px;
      &:first-child{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      @include desktop{
        padding: 0 15px;
      }
    }
  }
}

.language{
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;

  @include phones{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  & > span {
    color: $orange;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    margin-right: 30px;
  }

  &-main{
    height: 40px;
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid rgba(#fff, .05);
    border-radius: 10px;

    .flag-icon{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
    }


    svg{
      @include posY;
      right: 12px;
      fill: #fff;
      opacity: 0.17;
      width: 6px;
      height: 6px;
    }
  }

  &-hide{
    position: absolute;
    right: 0;
    top: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 270px;
    background-color: rgba(#fff, .95);
    border-radius: 10px;
    opacity: 0;
    transition: all .3s ease;
    pointer-events: none;
    
  }

  &__item{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    font-size: 12px;
    opacity: 0.35;
    color: #002654;
    transition: all .3s ease;
    text-decoration: none;

    .flag-icon{
      width: 20px;
      height: 20px;
      margin-left: 15px;
    }

    &.active, &:hover{
      opacity: 1;
      font-weight: 900;
    }
  }

  &:hover{
    .language-hide{
      opacity: 1;
      pointer-events: all;
    }
  }
}