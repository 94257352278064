.cabinet{
  &-header{
    padding: 35px 0;

    &__box{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      @include desktop{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
      }
    }

    .logo{
      margin-bottom: 0;
    }
  }

  &-logout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    font-family: $d;
    font-weight: 400;
    font-size: 12px;
    transition: all .3s ease;

    &:hover{
      color: $orange;
    }

    svg{
      transform: scaleX(-1);
      fill: #2c4d76;
      width: 18px;
      height: 18px;
      margin-right: 10px;

    }
  }

  &-hero{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: right;
    margin-right: 60px;

    @include desktop{
      margin-right: 0;
      margin-bottom: 10px;
    }

    p{
      text-transform: uppercase;
      color: $orange;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 5px;
      
    }

    span{
      color: #33537b;
      font-family: $d;
      font-weight: 12;
      font-weight: 400;
    }
  }

  &-nav{
    border-top: 1px solid #0d315d;
    background-color: #01234d;

    &__list{
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      width: 100%;

      @include tablets{
        flex-wrap: wrap;
      }

      a{
        width: calc(100% / 7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 5px 20px 5px;
        text-decoration: none;
        position: relative;
        margin-top: -1px;
        border-top: 2px solid transparent;
        transition: all .3s ease;

        @include laptop{
          padding: 10px 5px 15px 5px;
        }

        @include tablets{
          width: calc(100% / 4);
        }

        @include phones{
          width: calc(100% / 3);
        }
        
        p{
          font-family: $d;
          font-weight: 900;
          font-size: 14px;
          color: #fff;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 5px;

          @include laptop{
            font-size: 12px;
          }
        }

        span{
          font-weight: 400;
          font-size: 10px;
          color: #2c4d76;
          text-transform: uppercase;

          @include laptop{
            font-size: 8px;
          }
        }

        &:after{
          @include posX;
          transform: translate(-50%, -50%);
          top: 100%;
          content: '';
          border: 2px solid #01234d;
          background-color: #002654;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          transition: all .3s ease;
        }

        &:hover, &.active{
          background-color: #002654;
          border-top-color: $orange;

          .cabinet-nav__icon{
            background-color: #01234d;

            svg{
              fill: $orange;
            }
          }

          &:after{
            background-color: $orange;
            border-color: #002654;
          }
        }
      }
    }

    &__icon{
      width: 30px;
      height: 30px;
      background-color: #002654;
      border-radius: 8px;
      position: relative;
      margin-bottom: 12px;
      transition: all .3s ease;

      svg{
        @include posXY;
        fill: #294b74;
        width: 15px;
        height: 15px;
        transition: all .3s ease;
      }
    }
  }

  &-content{
    padding: 70px 0 40px 0;
    border-bottom: 1px solid #062d5d;

    .main-btn{
      font-size: 16px;
      padding: 0 20px;
      height: 45px;

      @include phones-hor{
        padding: 0 10px;
        height: 35px;
        font-size: 14px;
      }
    }
  }

  &-balance{
    width: 100%;
    background-color: #01234d;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px;
    margin-bottom: 30px;

    &__top{
      width: 100%;
      padding: 30px 40px;

      @include phones-hor{
        padding: 20px 15px;
      }
    }

    &__bot{
      width: 100%;
      padding: 7px;
    }

    &__total{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 25px;
      border-bottom: 1px solid #23456e;
      margin-bottom: 30px;

      &-title{
        padding-left: 50px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        p{
          font-weight: 800;
          color: $orange;
          font-size: 14px;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        span{
          color: #23456e;
          font-size: 10px;
          font-weight: 400;
          text-transform: uppercase;
        }

        svg{
          @include posY;
          left: 0;
          width: 30px;
          height: 30px;
          fill: #3579ca;
        }
      }

      &-val{
        font-family: $dc;
        font-weight: 900;
        font-size: 25px;
        color: $orange;
        text-transform: uppercase;

        @include phones-hor{
          font-size: 20px;
        }

        span{
          color: #23456e;
        }
      }
    }

    &__content{
      width: 100%;
      padding-right: 40px;
      position: relative;

      .swiper-container{
        width: 100%;
      }

      &-item{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &-row{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 30px;

        &:last-child{
          margin-bottom: 0;
        }

        p{
          color: #23456e;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 5px;
          font-family: $d;
        }

        span{
          color: #3579ca;
          font-weight: 900;
          font-size: 30px;
          line-height: 1;
          font-family: $dc;

          sup{
            font-size: 13px;
            color: $orange;
            font-weight: 700;
            top: -2px;
          }
        }
      }
    }

    &__nav{
      @include posY;
      right: 0;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .swiper-button{
        &-prev, &-next{
          position: relative;
          bottom: auto;
          left: auto;
          right: auto;
          top: auto;
          margin: 0;
          background: transparent;
          width: 17px;
          height: 17px;

          &:hover{
            svg{
              fill: $orange;
            }
          }
          svg{
            width: 17px;
            height: 17px;
            fill: #23456e;
            transition: all .3s ease;
          }
        }

        &-prev{
          transform: rotate(90deg);
        }
        &-next{
          transform: rotate(-90deg);
        }
      }
    }

    &__wallets{
      width: 100%;
      height: 400px;

      .swiper-container{
        width: 100%;
        height: 100%;
      }

      .swiper-slide{
        &-active{
          .cabinet-balance__wallets-title{
            p{
              color: $orange;
            }
          }
          .cabinet-balance__wallets-icon{
            background-color: $orange;
            svg{
              fill: #2b4476;
            }
          }
          .cabinet-balance__wallets-item{
            &:after{
              opacity: 1;
            }
          }
        }
      }

      &-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        padding: 30px 20px 30px 80px;
        @include bgCover('cab-balance-bg.png');
        border-radius: 8px;
        cursor: pointer;

        &:after{
          left: 0;
          transform: translate(-50%, -50%);
          top: 50%;
          width: 8px;
          height: 65px;
          position: absolute;
          content: '';
          border-radius: 20px;
          background-color: #294b74;
          opacity: 0;
          transition: all .3s ease;
        }
      }

      &-icon{
        @include posY;
        width: 40px;
        height: 40px;
        left: 20px;
        border-radius: 8px;
        background-color: #01234d;

        svg{
          @include posXY;
          width: 20px;
          height: 20px;
          fill: #2c4d76;
        }
      }

      &-settings{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        
        & > span{
          margin-right: 20px;
          font-weight: 700;
          text-transform: uppercase;
          color: #294b74;
          font-size: 13px;
        }

        &__icon{
          cursor: pointer;
          text-decoration: none;
          position: relative;

          &:hover{
            span{
              opacity: 1;
            }
          }

          span{
            position: absolute;
            right: 0;
            bottom: calc(100% + 8px);
            white-space: nowrap;
            height: 25px;
            min-width: 80px;
            background-color: #fff;
            border-radius: 8px;
            color: #294b74;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            padding: 0 5px;
            line-height: 25px;
            opacity: 0;
            pointer-events: none;
            transition: all .3s ease;
          }

          &:hover{
            svg{
              fill: #fff;
            }
          }

          svg{
            width: 25px;
            height: 25px;
            fill: #294b74;
            transition: all .3s ease;
          }
        }
      }

      &-title{
        p{
          color: #294b74;
          font-weight: 700;
          font-size: 13px;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        span{
          color: #294b74;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }

  &-head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;

    @include laptop{
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    @include tablets{
      margin-bottom: 30px;
    }

    .main-subtitle{
      margin-bottom: 0;

      @include laptop{
        margin-bottom: 30px;
      }
    }
  }

  &-btns{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @include phones-hor{
      flex-wrap: wrap;
      justify-content: center;
    }

    .main-btn{
      margin-right: 10px;

      @include phones-hor{
        margin-bottom: 10px;
      }

      &:last-child{
        margin-right: 0;

        @include phones-hor{
          margin-right: 10px;
        }
      }
    }
  }

  &-stat{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &__item{
      width: calc(50% - 30px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-left: 65px;
      margin: 40px 0;

      @include tablets{
        width: 100%;
        margin: 20px 0;
      }

      &-icon{
        @include posY;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 8px;
        background-color: #0b386d;

        svg{
          @include posXY;
          fill: $blue-l;
          width: 20px;
          height: 20px;
        }
      }

      &-title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        p{
          font-size: 14px;
          font-weight: 800;
          color: #fff;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        span{
          font-size: 10px;
          font-weight: 400;
          color: $orange;
          text-transform: uppercase;
        }
      }

      &-val{
        font-family: $dc;
        font-weight: 900;
        font-size: 30px;
        color: #3579ca;

        @include phones-hor{
          font-size: 24px;
        }
      }
    }
  }

  &-refs{
    background-color: #01234d;
    border-radius: 8px;
    padding: 30px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 40px;

    @include phones-hor{
      padding: 20px 15px 10px 15px;
    }

    &__title{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: #2c4d76;
      padding-left: 10px;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-size: 12px;

      @include phones-hor{
        margin-bottom: 15px;
      }

      svg{
        fill: #2c4d76;
        transform: rotate(90deg) scaleY(-1);
        width: 15px;
        height: 15px;
        margin-right: 25px;
      }
    }

    &__box{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      & > svg{
        width: 30px;
        height: 30px;
        fill: #2c4d76;
        margin-right: 20px;
      }
    }

    &__wrap{
      border: 1px solid #2c4d76;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: calc(100% - 50px);
      padding: 0 125px 0 25px;
      height: 60px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      position: relative;

      @include phones-hor{
        padding: 0 80px 0 25px;
      }

      &:after{
        height: 2px;
        width: calc(100% - 40px);
        @include posX;
        bottom: 10px;
        background-color: $blue-l;
        content: '';
      }

      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__copy{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: $blue-l;
      color: #fff;
      font-family: $d;
      font-size: 11px;
      font-weight: 900;
      @include posY;
      right: 15px;
      height: 38px;
      padding: 0 20px;
      text-transform: uppercase;
      border-radius: 8px;
      border-bottom-left-radius: 0;
      cursor: pointer;

      @include phones-hor{
        span{
          display: none;
        }
      }

      svg{
        fill: #fff;
        margin-right: 10px;
        width: 16px;
        height: 16px;

        @include phones-hor{
          margin-right: 0;
        }
      }
    }

    &__sponsor{
      width: calc(100% - 50px);
      height: 60px;
      padding: 5px 20px;
      background-color: #13355e;
      font-weight: 700;
      font-size: 24px;
      color: #2c4d76;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
    }

    &__left{
      width: 70%;
      padding-right: 40px;

      @include laptop{
        width: calc(50% - 20px);
        padding-right: 0;
      }

      @include tablets{
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &__right{
      width: 30%;

      @include laptop{
        width: calc(50% - 20px);
      }

      @include tablets{
        width: 100%;
      }
    }

    &_full{
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      @include tablets{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &-stat{
      width: 100%;
      position: relative;
      background-color: #042a59;
      border-radius: 8px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      margin-bottom: 10px;

      @include laptop{
        flex-wrap: wrap;
        padding: 20px 20px 10px 20px;
      }

      & > span{
        @include posY;
        left: 20px;
        color: rgba(#01234d, .48);
        font-weight: 800;
        font-size: 43px;
        text-transform: uppercase;
      }

      &__item{
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% / 9);

        @include laptop{
          width: calc(100% / 5);
          margin-bottom: 10px;
        }

        @include phones-hor{
          width: calc(100% / 3);
        }
        @include phones{
          width: calc(100% / 2);
        }

        &-title{
          padding-left: 30px;
          position: relative;
          color: #2c4d76;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          margin-bottom: 12px;
        }

        &-val{
          color: #2c4d76;
          font-size: 16px;
          font-weight: 900;
          font-family: $dc;
        }

        &-icon{
          @include posY;
          left: 0;
          width: 20px;
          height: 20px;
          border-radius: 3px;
          background-color: $orange;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-align: center;

          svg{
            @include posXY;
            width: 10px;
            height: 10px;
            fill: #2c4d76;
          }
        }
      }
    }
  }

  &-amount{
    width: 100%;
    margin-bottom: 50px;
   
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &-input{
    width: 100%;
    position: relative;
    border: 1px solid #0a2f5b;
    border-radius: 8px;
    padding: 0 70px 0 15px;

    input{
      width: 100%;
      background-color: transparent;
      border: none;
      height: 60px;
      outline: none;
      font-weight: 800;
      color: $orange;
      font-size: 16px;
    }

    span{
      @include posY;
      background-color: $orange;
      border-radius: 8px;
      color: #002654;
      display: block;
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      right: 15px;
      font-size: 22px;
      font-family: $d;
      font-weight: 800;
      line-height: 40px;

    }
  }

  &-payment{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;

    label{
      display: flex;
      width: calc(50% - 3px);
      margin-bottom: 6px;

      @include phones-hor{
        width: 100%;
      }

      input{
        display: none;

        &:checked + .cabinet-payment__item{
          border: 1px solid #244670;
          p{
            color: $orange;
          }
  
          .cabinet-payment__item-check{
            background-color: #fff;
            border-color: #fff;
  
            svg{
              opacity: 1;
            }
          }
        }
      }
    }

    &__item{
      height: 75px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 60px;
      position: relative;
      border: 1px solid #0b305c;
      border-radius: 8px;
      width: 100%;
      cursor: pointer;
      transition: all .3s ease;

      &:hover{
        border: 1px solid #244670;        
      }

      p{
        transition: all .3s ease;
        color: #fff;
        font-weight: 700;
        font-size: 13px;
        text-transform: uppercase;

        span{
          color: #2c4d76;
        }
      }

      &-icon{
        @include posY;
        left: 15px;
        width: 30px;
        height: 30px;
        background-color: $orange;
        border-radius: 8px;
        

        svg{
          @include posXY;
          width: 20px;
          height: 20px;
          fill: #002654;
        }
      }

      &-check{
        @include posY;
        right: 15px;
        width: 25px;
        height: 25px;
        transition: all .3s ease;
        border-radius: 4px;
        border: 1px solid #244670;

        svg{
          @include posXY;
          width: 15px;
          height: 15px;
          transition: all .3s ease;
          fill: #002654;
          opacity: 0;
        }
      }
    }
  }

  &-plans{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    @include desktop{
      justify-content: space-around;
    }

    &__item{
      max-width: 340px;
      width: calc(100% / 3 - 15px);
      cursor: pointer;
      margin-bottom: 40px;

      @include desktop{
        width: calc(100% / 2 - 15px);
      }

      @include tablets{
        width: 100%;
      }

      input{
        display: none;

        &:checked + .plan{
          .plan-icon{
            background-color: $orange;

            &:after{
              display: none;
            }

            svg{
              fill: #2b4476;
            }
          }
        }
      }

      .plan{
        background-image: url(../img/cab-plan-bg.png);
        padding-left: 120px;

        @include phones-hor{
          padding-left: 140px;
        }

        &-percent{
          @include posY;
          width: 120px;
          text-align: center;
          font-family: $dc;
          font-weight: 900;
          font-size: 36px;
          color: $orange;
          left: 0;
        }

        &:hover{
          .plan-icon{
            background-color: $orange;

            &:after{
              display: none;
            }

            svg{
              fill: #2b4476;
            }
          }
        }

        &-top{
          padding-right: 30px;
          border-bottom: none;
        }

        &-icon{
          background-color: #0c3566;
          transition: all .3s ease;

          &:after{
            @include posXY;
            width: 100%;
            height: 100%;
            content: '';
            border-radius: 50%;
            border: 1px solid #244670;
          }

          svg{
            fill: #2b4476;
            transition: all .3s ease;
          }
        }
      }
    }
  }

  &-btn-center{
    text-align: center;
    width: 100%;
  }

  &-table{
    width: 100%;
    margin-bottom: 25px;

    .cabinet-refs__title{
      margin-bottom: 0;
      padding-left: 0;
    }

    table{
      width: 100%;
      border-collapse: collapse;
    }

    td{
      padding: 20px 5px;

      &:before{
        color: #2c4d76;
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    &__text{
      color: #fff;
      font-family: $d;
      font-weight: 700;
      font-size: 12px;
    }

    &__date{
      color: #23456e;
      font-family: $d;
      font-weight: 700;
      font-size: 12px;
    }

    &__val{
      color: $orange;
      font-size: 16px;
      font-family: $dc;
      font-weight: 900;
    }

    &__wallet{
      position: relative;
      padding-left: 45px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;

      .cabinet-payment__item-icon{
        left: 0;
      }
    }

    &__status{
      position: relative;
      padding-left: 20px;
      font-weight: 700;
      font-family: $d;
      font-size: 12px;

      &:after{
        width: 6px;
        height: 6px;
        @include posY;
        left: 0;
        border-radius: 50%;
        content: '';
      }

      &.green{
        color: #269d26;

        &:after{
          background-color: #269d26;
        }
      }
      &.red{
        color: #9d2626;

        &:after{
          background-color: #9d2626;
        }
      }
      &.yellow{
        color: #bca53a;

        &:after{
          background-color: #bca53a;
        }
      }
    }
  }
  
  &-lvl{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;

    &__item{
      margin-right: 25px;
      padding-left: 15px;
      margin-bottom: 12px;
      position: relative;
      font-size: 10px;
      text-transform: uppercase;
      color: #2c4d76;
      transition: all .3s ease;
      text-decoration: none;

      &:after{
        position: absolute;
        top: 3px;
        left: 0;
        border-radius: 50%;
        content: '';
        background-color: #2c4d76;
        width: 6px;
        height: 6px;
      }

      &:before{
        position: absolute;
        right: 0;
        top: calc(100% + 10px);
        height: 2px;
        width: calc(100% - 15px);
        background-color: transparent;
        transition: all .3s ease;
        content: '';
      }

      &:hover, &.active{
        color: #fff;

        &:after, &:before{
          background-color: $orange;
        }
      }

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &-banner{
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__img{
      width: 100%;
      margin-bottom: 40px;

      img{
        max-width: 100%;
      }
    }

    &__box{
      background-color: #01234d;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      border-radius: 8px;
      position: relative;
      height: 70px;
      padding: 5px 140px 5px 30px;

      @include phones-hor{
        padding: 5px 85px 5px 30px;
      }
    }

    &__size{
      font-weight: 700;
      font-size: 13px;
      color: #2c4d76;
      text-transform: uppercase;
      margin-right: 30px;
      font-family: $dc;
    }

    &__link{
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  &-settings{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .main-title{
      margin-bottom: 40px;
    }

    &__title{
      font-weight: 500;
      font-size: 14px;
      color: $orange;
      text-transform: uppercase;
      margin-bottom: 45px;
      width: 100%;
    }

    &__form{
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 20px 30px;
      background-color: #01234d;
      box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.05);
      width: 100%;
      margin-bottom: 40px;
      border-radius: 8px;

      .main-title{
        width: 100%;
      }

      p{
        margin-bottom: 5px;
      }

      &_wallet{
        .cabinet-settings__col{
          width: calc(50% - 15px);
          margin-bottom: 0;

          @include phones{
            width: 100%;
          }
        }
      }
    }

    &__col{
      width: calc(33% - 15px);

      @include tablets{
        width: calc(50% - 15px);
      }

      @include phones{
        width: 100%;
      }
    }

    &__btn{
      text-align: center;
      width: 100%;
    } 

    &__input{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 50px;
      position: relative;
      margin-top: 10px;

      p{
        position: absolute;
        bottom: 100%;
        left: 0;
        font-weight: 300;
        font-size: 12px;
        color: $orange;
      }

      svg{
        @include posY;
        right: 12px;
        width: 20px;
        height: 20px;
        fill: $orange;
      }

      ::placeholder{
        color: $orange;
        text-transform: uppercase;
      }

      input{
        height: 40px;
        line-height: 40px;
        width: 100%;
        border: 1px solid #0b305c;
        border-radius: 8px;
        padding: 0 40px 0 15px;
        outline: none;
        font-weight: 500;
        font-size: 10px;
        color: $orange;
        font-weight: 400;
        background-color: transparent;

        &:focus + svg{
          fill: url(#gr-o);
        }
      }
    }

    &__checkbox{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;
      }

      p{
        font-weight: 300;
        color: $orange;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;

        @include phones-hor{
          font-size: 12px;
        }
      }

      label{
        display: flex;
        cursor: pointer;
      }

      input{
        display: none;

        &:checked + span{

          &:after{
            left: 0;
          }

          .on{
            color: #0b305c;
          }

          .off{
            color: $orange;
          }
        }

        & + span{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background-color: #0b305c;
          border-radius: 30px;
          width: 90px;
          position: relative;

          &:after{
            width: 45px;
            border-radius: 30px;
            @include posY;
            left: 45px;
            height: 100%;
            content: '';
            z-index: 0;
            transition: all .3s ease;
            background-color: $orange;
          }

          span{
            padding: 5px 0;
            width: 40px;
            border-radius: 30px;
            text-align: center;
            font-weight: 500;
            font-size: 13px;
            text-transform: uppercase;
            width: 45px;
            position: relative;
            z-index: 1;

            &.on{
              color: $orange;
              transition: all .3s ease;
            }

            &.off{
              color: #0b305c;
              transition: all .3s ease;
            }
          }
        }
      }


    }

    &__gg{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      padding-left: 110px;
      margin-bottom: 50px;

      @include phones-hor{
        padding-left: 0;
        padding-top: 110px;
      }

      &-img{
        @include posY;
        left: 0;
        width: 90px;
        height: 90px;

        @include phones-hor{
          top: 0;
          left: 0;
          transform: translateY(0);
        }

        img{
          width: 100%;
          height: 100%;
        }
      }

      p{
        font-size: 12px;
        color: #fff;
        margin-bottom: 10px;

        a{
          color: $orange;

          &:hover{
            text-decoration: none;
          }
        }
      }

      &-form{
        position: relative;
        width: 100%;
        

        .cabinet-settings__input{
          margin-bottom: 0;
          height: 100%;

          input{
            height: 45px;
            line-height: 45px;
          }
        }

        button{
          @include posY;
          right: 0;
          height: 100%;
          border: none;
          
          outline: none;
          cursor: pointer;
        }
      }
    }

    &__auth{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      &-col{
        width: calc(25% - 10px);
        margin-bottom: 15px;

        @include tablets{
          width: calc(50% - 10px);
        }

        @include phones-hor{
          width: 100%;
        }

        .cabinet-settings__checkbox{
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
  
          p{
            font-size: 11px;
          }
        }
      }

      

      .cabinet-settings__input{
        p{
          font-size: 11px;
        }
      }

      &-row{
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}