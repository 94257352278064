.stat{
  padding: 40px 0;
  margin-bottom: -80px;

  @include tablets{
    padding: 40px 0;
  }

  &-last{
    width: 100%;
    margin-bottom: 30px;

    .tabs{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      margin-bottom: 40px;
    }

    .tab{
      padding-left: 15px;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      font-family: $c;
      font-weight: 400;
      color: #fff;
      text-transform: uppercase;
      font-size: 10px;
      position: relative;
      margin-right: 30px;
      opacity: 0.3;
      transition: all .3s ease;
      cursor: pointer;

      svg{
        position: absolute;
        left: 100%;
        bottom: -9px;
        transform: rotate(90deg);
        fill: $orange;
        width: 10px;
        height: 10px;
        opacity: 0;
        transition: all .3s ease;
      }

      &:hover, &.active{
        opacity: 1;
        border-bottom: 2px solid $orange;

        svg{
          opacity: 1;
        }
      }

      &:last-child{
        margin-right: 0;
      }

      &:after{
        position: absolute;
        left: 0;
        top: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        content: '';
        background-color: $orange;
      }

      &_content{
        width: 100%;
      }

      &_item{
        width: 100%;
      }
    }

    &__item{
      padding: 25px 50px 20px 25px;
      border-radius: 10px;
      background-color: #0c3566;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      overflow: hidden;
    }

    &__title{
      text-transform: uppercase;
      color: $blue-l;
      font-weight: 400;
      font-size: 10px;
      padding-left: 35px;
      position: relative;
      margin-bottom: 30px;

      &-icon{
        @include posY;
        left: 0;
        width: 25px;
        height: 25px;
        background-color: $blue-l;
        border-radius: 6px;

        svg{
          @include posXY;
          width: 15px;
          height: 15px;
          fill: #fff;

        }
      }
    }

    &__cont{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      position: relative;
      padding: 5px 0;

      &:after{
        left: -30px;
        @include posY;
        width: 10px;
        height: 100%;
        background-color: $orange;
        border-radius: 20px;
        content: '';
      }
    }

    &__name{
      font-weight: 700;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &__val{
      font-family: $dc;
      font-weight: 900;
      font-size: 22px;
      color: $orange;
      text-transform: uppercase;
    }

    &__date{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      top: 50%;
      left: calc(100% - 40px);
      transform: translate(-50%, -50%) rotate(-90deg);
      color: #00234d;
      line-height: 1;
      font-weight: 400;
      font-size: 12px;
      padding: 15px 25px 15px 0;
      white-space: nowrap;
      border-top: 1px solid rgba(#fff, .08);

      svg{
        @include posY;
        right: 0;
        fill: #00234d;
        width: 15px;
        height: 15px;
      }
    }
  }

  &-slider{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .swiper{
      &-container{
        width: 100%;

        @include phones{
          margin-bottom: 30px;
        }
      }

      &-button{
        &-prev, &-next{
          background: none;
          position: relative;
          margin: 0;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          transform: translate(0, 0);
          width: auto;
          height: auto;
          display: flex;
          outline: none;

          &:hover svg{
            opacity: 1;
          }

          svg{
            width: 15px;
            height: 15px;
            fill: #fff;
            opacity: 0.18;
            transition: all .3s ease;
          }
        }

        &-next{
          transform: translate(0, 0) scaleX(-1);
        }
      }
    }

    &__nav{
      max-width: 145px;
      width: 100%;
      height: 35px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid rgba(#fff, .18);
      padding: 0 15px;
      position: absolute;
      right: 0;
      bottom: calc(100% + 40px);

      @include phones{
        position: static;
      }
    }
  }

  &-invest{
    padding: 35px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include bgCover('stat-invest-bg.png');
    border-radius: 8px;
    max-width: 280px;
    margin: 0 auto 50px auto;
    position: relative;

    &__line{
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      @include posXY;
      pointer-events: none;
      border: 1px solid rgba(#0c3566, .6);
      border-radius: 8px;

      &:after{
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        content: '';
        @include posXY;
        pointer-events: none;
        border: 1px solid rgba(#0c3566, .25);
        border-radius: 8px;
      }

      &:before{
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        content: '';
        @include posXY;
        pointer-events: none;
        border: 1px solid rgba(#0c3566, .18);
        border-radius: 8px;
      }
    }

    &:after{
    }

    .main-text{
      margin-bottom: 35px;
    }

    .main-btn{
      margin: 0 auto -60px auto;
      position: relative;
      z-index: 2;
    }

    &__title{
      color: $orange;
      font-weight: 800;
      font-size: 14px;
      text-transform: uppercase;
      width: 100%;
      margin-bottom: 30px;
      letter-spacing: 5px;

      span{
        display: block;
        color: $blue-l;
        font-size: 20px;
      }
    }

    
  }

  &-list{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    flex-wrap: wrap;
  }

  &-item{
    width: calc(100% / 6);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #01234d;
    padding: 30px 30px 70px 30px;
    position: relative;
    overflow: hidden;
    border-right: 1px solid rgba(#0c3566, .35);
    

    @include desktop{
      width: calc(100% / 3);
    }

    @include phones{
      width: calc(100% / 2);
    }

    &:after{
      width: 50px;
      height: 10px;
      border-radius: 20px;
      top: -5px;
      left: 22px;
      position: absolute;
      content: '';
    }

    &:nth-child(odd){
      .stat-item__val{
        color: $orange;
      }

      &:after{
        background-color: $orange;
      }
    }
    &:nth-child(even){
      .stat-item__val{
        color: $blue-l;
      }

      &:after{
        background-color: $blue-l;
      }
    }

    svg{
      width: 35px;
      height: 35px;
      fill: #33537b;
      margin-bottom: 20px;
    }

    &__num{
      background-color: #012149;
      text-align: center;
      width: 100%;
      padding: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      font-family: $dc;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      border-top: 1px solid rgba(#0c3566, .35);
    }

    &__title{
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-left: 1px solid #fff;
      margin-bottom: 20px;
      background-image: url(../img/stat-item-bg.png);
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: contain;
      

      p{
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      span{
        font-size: 9px;
        text-transform: uppercase;
        color: #33537b;
        font-weight: 700;
      }
    }

    &__val{
      font-weight: 900;
      font-family: $dc;
      font-size: 34px;

      @include tablets{
        font-size: 24px;
      }
    }
  }
}