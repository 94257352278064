.quote{
  padding: 50px 0;
  @include bgCover('quote-bg.png');
  background-position: 0 center;

  &-head{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: right;
  }

  &-title{
    font-family: $d;
    font-weight: 700;
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1.3;
  }

  &-subtitle{
    color: $orange;
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &-hero{
    color: #365f8f;
    padding-right: 30px;
    position: relative;
    font-size: 11px;
    font-weight: 400;

    &:after{
      height: 1px;
      width: 20px;
      background-color: #365f8f;
      content: '';
      right: 0;
      @include posY;
    }
  }

  &-text{
    position: relative;
    color: #fff;
    margin-bottom: 20px;

    p{
      font-family: $p;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
    }
  }
  
  &-cert{
    .cert-num{
      margin-bottom: 0;
      span{
        font-weight: 300;
        color: #fdd186;
      }
    }

    &__box{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 380px;
      margin-bottom: 20px;

      p{
        color: #315887;
        font-size: 13px;
        line-height: 1.5;
      }
    }

    &__icon{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 15px;

      &:after, &:before{
        display: inline-block;
        height: 15px;
        width: 1px;
        background-color: #102f54;
        content: '';
      }

      img{
        margin: 3px 0;
      }
    }
  }

  &-sub{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    span{
      margin-right: 15px;
      padding-right: 40px;
      position: relative;
      color: #365f8f;
      font-size: 11px;
      font-weight: 400;

      &:after{
        @include posY;
        right: 0;
        width: 20px;
        height: 1px;
        content: '';
        background-color: #365f8f;
      }
    }
  }

  .main-btn{
    @include tablets{
      margin-bottom: 30px;
    }
  }
}