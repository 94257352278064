.cert{
  position: relative;

  background-image: url(../img/cert-bg1.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 110% 100%;

  @include desktop2300{
    background-size: 100% 100%;
  }

  @include tablets{
    background-image: none;
  }
  
  // &:after{
  //   top: 0;
  //   right: 0;
  //   position: absolute;
  //   height: 100%;
  //   width: 70%;
  //   background-image: url(../img/cert-bg.png);
  //   background-repeat: no-repeat;
  //   background-position: right center;
  //   background-size: contain;
  //   content: '';
  //   z-index: 1;
  //   pointer-events: none;

  //   @include laptop{
  //     display: none;
  //   }
  // }

  .container{
    position: relative;
    z-index: 2;
  }

  .main-text{

    &:last-child{
      margin-bottom: 0;
    }
    p{
      font-size: 14px;
    }
  }

  .cert-num{
    &:after{
      top: -20px;
      @include posX;
      width: 100%;
      height: 1px;
      background-color: $orange;
      content: '';
    }
  }

  &-num{
    color: #fff;
    font-size: 26px;
    font-weight: 500;
    font-family: $s;
    margin-bottom: 10px;
    position: relative;

    span{
      color: #fdd186;
    }
  }

  &-text{
    margin-bottom: 10px;
    p{
      font-size: 16px;
      color: #183f6e;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 1.5;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 12px;
    color: $orange;
    text-transform: uppercase;
    text-decoration: none;

    svg{
      margin-left: 20px;
      width: 18px;
      height: 18px;
      transform: scaleX(-1);
      fill: $orange;
    }
  }

  &-img{
    width: 100%;
    text-align: center;

    @include phones-hor{
      margin: 15px auto;
    }

    img{
      max-width: 100%;
    }
  }

  &-title{
    font-family: $d;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 25px;

    span{
      color: $orange;
    }
  }

  &-slider{
    width: 45vw;

    @include laptop{
      width: 100%;
      margin-top: 30px;
    }

    .swiper{
      &-conteiner{
        width: 100%;
      }

    }
  }

  &-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0;


    &__val{
      font-family: $dc;
      font-weight: 900;
      font-size: 22px;
      color: #fff;
      margin-bottom: 15px;
    }

    &__wallet{
      padding-top: 15px;
      border-top: 1px solid #254c7b;
      color: #254c7b;
      font-size: 10px;
      font-weight: 700;
      width: 100%;
      position: relative;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      

      &:after{
        border: 4px solid transparent;
        content: '';
        
      }

      
    }

    &_up{
      .cert-item__wallet:after{
        border-bottom-color: #55c349;
      }
    }
    &_down{
      .cert-item__wallet:after{
        border-top-color: red;
      }
    }
  }

  &-faq{
    padding-left: 60px;
    width: 100%;
    margin-bottom: 50px;

    &:after{
      position: absolute;
      bottom: 70px;
      @include posX;
      height: 800px;
      width: 100%;
      background-image: url(../img/contacts-box.png);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 100% 100%;
      content: '';
      z-index: -2;

      @include laptop{
        height: 100%;
        @include posXY;
      }
    }
    

    &__icon{
      position: absolute;
      top: 100px;
      right: -30px;
      z-index: -1;
      pointer-events: none;
      

      svg{
        width: 250px;
        height: 250px;
        fill: transparent;
        stroke-width: 4px;
        stroke: #0e2e56;
      }
    }

    .cert-num{
      position: relative;
      font-weight: 900;
      text-transform: uppercase;

      &__icon{
        @include posY;
        left: -80px;
        width: 40px;
        height: 40px;
        background-color: $orange;
        border-radius: 50%;

        @include laptop{
          left: -50px;
        }

        svg{
          fill: #2b4476;
          width: 20px;
          height: 20px;
          @include posXY;
        }
      }

      span{
        font-weight: 300;
        color: $orange;
      }
    }

    &__bot{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .cert-img{
        margin-right: -35px;
        margin-bottom: -35px;
      }
    }

    &_contacts{
      padding-right: 40px;
      position: relative;

      @include laptop{
        padding: 30px 40px 30px 30px;
      }

      &:after{
        position: absolute;
        bottom: -30px;
        @include posX;
        height: 800px;
        width: 100%;
        background-image: url(../img/contacts-box.png);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;
        content: '';
        z-index: -2;

        @include laptop{
          height: 100%;
          @include posXY;
        }
      }

      .cert-num{
        font-weight: 700;
        margin-bottom: 30px;
        span{
          font-weight: 700;
        }
      }

      .cert-text{
        p{
          color: $blue-l;
        }
      }
    }
  }
}

.faq{

  .main-text{
    margin-bottom: 30px;
  }

  .main-btn{
    margin-bottom: 50px;
  }

  .cert-num{
      margin-bottom: 20px;
      span{
        color: #fdd186;
      }
    }

  &-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 35px 0;
    border-top: 1px solid #153c6a;
    position: relative;
    overflow: hidden;

    @include tablets{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &:after{
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(0, -50%);
      border-radius: 20px;
      height: 10px;
      width: 45px;
      background-color: #153c6a;
      content: '';
    }

    &__title{
      width: calc(50% - 15px);
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      @include tablets{
        width: 100%;
        margin-bottom: 20px;
      }

      span{
        padding-right: 25px;
        margin-right: 20px;
        font-family: $dc;
        font-weight: 300;
        font-size: 18px;
        color: $orange;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        
        &:after{
          @include posY;
          right: 0;
          content: '/';
          font-family: $d;
          font-weight: 700;
          font-size: 18px;
          color: #153c6a;
        }
      }

      p{
        font-family: $d;
          font-weight: 700;
          font-size: 18px;
          color: #fff;
      }
    }

    .main-text{
      width: calc(50% - 15px);
      margin-bottom: 0;

      @include tablets{
        width: 100%;
      }
    }
  }

  &-text{
    width: 100%;
    margin-bottom: 30px;
    padding-left: 20px;

    p{
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      padding-left: 20px;
      margin-bottom: 20px;
      border-left: 2px solid #153c6a;

      &:last-child{
        margin-bottom: 0;
      }
    }

    span{
      color: $orange;
      font-weight: 800;
    }
  }

  &-cert{
    border-radius: 8px;
    border: 1px solid #153c6a;
    margin-bottom: 50px;
    position: relative;

    &__line{
      position: absolute;
      top: 70px;
      right: calc(100% + 20px);
      width: 250px;
      height: 222px;

      @include desktop{
        display: none;
      }

      &-1{
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        background-color: #153c6a;

        &:after{
          transform-origin: 100% 50%;
          width: 20px;
          height: 2px;
          background-color: #153c6a;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-100%, -50%) rotate(-45deg);
          right: 0;
        }

        &:before{
          transform-origin: 100% 50%;
          width: 90px;
          height: 2px;
          background-color: #153c6a;
          content: '';
          position: absolute;
          top: calc(50% + 13px);
          left: -10px;
          transform: translate(-100%, -50%) rotate(-90deg);
          right: 0;
        }
      }

      &-2{
        width: 8px;
        height: 8px;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        background-color: #153c6a;

        &:after{
          transform-origin: 100% 50%;
          width: 100px;
          height: 2px;
          background-color: #153c6a;
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          right: 0;
        }

        &:before{
          transform-origin: 100% 50%;
          width: 173px;
          height: 2px;
          background-color: #153c6a;
          content: '';
          position: absolute;
          bottom: 2px;
          left: 100px;
          transform: translate(-100%, -50%) rotate(140deg);
          right: 0;
        }
      }
    }

    &__box{
      padding: 35px 80px 35px 40px;
      position: relative;

      p{
        color: #fff;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.5;
      }
    }

    &__icon{
      position: absolute;
      top: 30px;
      left: 0;
      padding: 5px 0;
      background-color: #002654;
      transform: translateX(-50%);
    }

    &__img{
      position: absolute;
      right: -40px;
      top: 10px;
    }

    &__title{
      font-weight: 400;
      font-size: 16px;
      color: #3071bf;
      line-height: 1.5;
      margin-bottom: 10px;
    } 

    &__bot{
      padding: 15px 90px;
      border-top: 1px solid #153c6a;
      position: relative;
      display: flex;
      text-align: center;
      justify-content: center;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      text-decoration: none;
      background: repeating-linear-gradient(-45deg, 
        #002654 0px,
        #002654 10px,
        #042a58 10px,
        #042a58 20px,
      );

      @include desktop{
        padding: 20px 60px;
      }

      p{
        font-size: 12px;
        color: $orange;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.5;
      }
    }

    &__arrow{
      position: absolute;
      left: 40px;
      top: 0;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background-color: #002654;
      border-radius: 50%;

      &:after{
        width: 32px;
        height: 32px;
        border: 1px solid #153c6a;
        @include posXY;
        content: '';
        border-radius: 50%;
      }

      svg{
        @include posXY;
        width: 11px;
        height: 11px;
        fill: $orange;
        transform: translate(-50%, -50%) scaleX(-1);
      }
    }
  }
}